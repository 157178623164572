.tk-vehicle {
    @import "../_shared/ModelList";

    &.vehicle-models {
        height: 100%;
        width: 100%;

        .loader {
            width: 3rem;
            height: 3rem;
            margin-left: calc(50% - 1.5rem);
        }

        .models__item {
            .inputs {
                .loader {
                    width: 24px;
                    height: 24px;
                    position: static;
                    margin: 0;
                }
            }
        }
    }
}
