.widget-telematics {
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .tooltip {
        width: 100%;

        &__content {
            padding: 1rem;
        }
    }

    &__content {
        position: relative;
        min-height: 17em;
        width: 33em;

        display: flex;
        flex-direction: column;

        .slides-view {
            flex: 1
        }

        .slide-1 {
            .def-list {
                margin: 2em 1em 0;
                display: flex;
    
                &__item {
                    height: 10em;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    text-align: center;
    
                    .icon {
                        margin-bottom: 0.5em;
                        opacity: .54;
                        transition: opacity 200ms ease;
                    }
    
                    &:hover {
                        .icon {
                            opacity: .87;
                        }
                    }
    
                    .battery-icon {
                        margin-right: 0.5rem;
                    }
                }
                &__term {
                    margin-top: auto;
                    margin-bottom: auto;
                    font-size: 1em;
                    line-height: 1.2em;
                    white-space: normal;
                }
                &__text {
                    margin-top: 0.5em;
                    color: #2196f3;
                    font-size: 1.5em;
                }
            }
        }
    
        .slide-2 {
            .def-list {
                margin: 1em 1em 0;
                display: flex;
                flex-wrap: wrap;
    
                &__item {
                    width: 50%;
                    display: flex;
                    margin-bottom: 1em;
                    .icon {
                        margin-right: 1em;
                        flex: 0 0 48px;
                        opacity: .54;
                        transition: opacity 200ms ease;
                    }
                    &:hover {
                        .icon {
                            opacity: .87;
                        }
                    }
                }
    
                &__term {
                    font-size: 0.9em;
                    line-height: 1.2;
                }
    
                &__text {
                    font-weight: bold;
                    color: #2196f3;
                    font-size: 1.2em;
                    margin-bottom: 0.2em;
                }
            }
        }
    
        .slide-3 {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            padding-top: 1.250em;
    
            .headline {
                font-size: 1.5em;
                margin-bottom: 1em;
                color: #202020;
            }
    
            .rating {
                display: flex;
                align-items: center;
                margin-bottom: 1em;
    
                .text {
                    font-size: 2em;
                    width: 4rem;
                }
    
                .chart {
                    width: 10em;
                    height: 3.5em;
                    background-size: contain;
                }
            }
            .description {
                display: flex;
                align-items: center;
    
                .chart {
                    width: 4rem;
                    opacity: .54;
                }
    
                .text {
                    color: #2196f3;
                    font-size: 1.2em;
                    font-weight: bold;
                }
            }
        }
        .update-view {
            display: flex;
            flex-direction: column;
            align-items: center;
    
            .btn {
                // width: 11em;
                padding: 1em;
                margin: 1em;
                line-height: 1.2em;
            }
    
            &__status {
                display: flex;
                align-items: center;
                margin: 1em 0 2em 0;
    
                .loader {
                    margin: 0 1em;
                }
    
                .icon {
                    opacity: .54;
                    transition: opacity 200ms ease;
                }
                
                &.is-active {
                    .icon {
                        opacity: .87;
                    }
                }
            }
    
            &__footnote {
                .icon {
                    vertical-align: text-bottom;
                    margin-left: 0.3em;
                }
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;

        .radio {
            margin: 0 1px;
        }
    }

    &__popup-header {
        display: flex;
        align-items: center;
        
        .icon {
            margin-right: .625em;
        }
    }

    &__options {
        position: absolute;
        right: 0;
        top: 0;
    }

    
}
