.widget-mvc {
    cursor: pointer;

    &.is-editing {
        .widget__card {
            overflow: unset;
        }
    }

    &__cover {
        text-align: center;

        .input--dropdown {
            margin-top: 1rem;
            text-align: left;

            .input__inner {
                padding-right: 1em;

                .input__field {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 0.9rem;
                }

                .input__icons {
                    right: 2px;
                }

                .popover {
                    font-size: 0.9rem;
                    width: 100%;
                    left: 0;
                    z-index: 6;

                    .input__list-item {
                        text-align: left;
                        padding: 0.3em;
                    }
                    &__inner {
                        padding: 2px;
                    }
                }

            }
        }
    }

    .widget__header {
        display: none;
    }

    .widget__inner {
        padding: 5px;
    }

    &__icon {
        margin-top: 1.6rem;
    }

    &__headline {
        font-size: 1rem;
    }

    &__provider-text {
        font-size: 1rem;
        text-align: left;
    }

    &.disabled {
        cursor: default;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }
}
