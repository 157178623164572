.dictionary {
    display: block;
    columns: 2;
    column-gap: 1em;
    margin: -0.5rem 0;

    &__item {
        display: flex;
        flex: 0 0 50%;
        padding: 0.5rem 0;
    }

    &__key {
        flex: 0 0 50%;
    }

    &__value {
        flex: 0 0 50%;
        font-weight: bold;
    }
}
