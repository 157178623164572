.memo-tooltip-icon {
    margin: auto 0;
    height: 100%;
    padding: 0 0.5em;

    .icon {
        opacity: 1;
    }

    &__tooltip {
        min-width: 40rem;
        padding: 0.5rem;
    }

    &__table {
        &__wrapper {
            margin-top: 1rem;
            display: flex;

            table {
                flex: 1;

                &:first-child {
                    margin-right: 1rem;
                }

                tr {
                    td {
                        padding: 0.5rem;
                    }

                    &:nth-child(odd) {
                        background-color: #f5f5f5;
                    }
                }
            }
        }
    }
}