.tk-vehicle.searchfield {

    flex: 10;

    .inputgroup {
        flex: 1;
        width: 30rem;

        // reset button to default size
        .btn {
            padding: 0.4em;
            font-size: 1rem;
        }
    }

    .suggest--new {
        .inputgroup__children {
            padding: 0.2rem 0 0.2rem 0.75rem;
        }
    }
}
