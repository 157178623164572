.price {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;

    &--two-column {
        flex-direction: column;
        align-items: stretch;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__name {
        margin-right: 1em;
    }

    &__group {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: flex-end;
        min-width: 5.5rem;
        justify-content: center;
        margin-right: 0.5em;

        > .memo {
            position: absolute;
            bottom: -16px;
            white-space: nowrap;
        }

        &__wrapper {
            display: flex;
        }

        &--two-column {
            align-items: flex-start;
            justify-content: flex-start;
            min-width: 5rem;

            &:first-child {
                justify-content: center;

                .price--info-only {
                    align-self: center;
                }
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .tooltip__content {
        white-space: nowrap;
        text-align: center;
    }
}
