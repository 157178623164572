.misc {
    .list {
        padding: 0 0;

        .list__cell {
            padding: 2em 0;

            &:first-child {
                margin: 0 .5em 0 0;
            }

            &:last-child {
                margin: 0 0 0 .5em;
            }

            &:before {
                content: "";
                display: block;
                width: 100%;
                height: 1em;
                top: -2em;
                position: relative;
                background: #dfe4e7;
            }

            .main-text {
                font-weight: 700;
            }

            .battery-icon {
                margin-right: 0.5rem;
            }
        }
    }
}
