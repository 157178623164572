.tk-crm {
    &.navigation {
        .toolbar {
            display: block;
            position: relative;

            &::before {
                position: absolute;
                top: 0;
            }
        }
    }
}