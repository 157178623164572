.manufacturers {
    &__list-wrapper {
        margin: -.5em;
    }

    &__header {
        display: flex;
        align-items: center;
        padding: .5em;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        text-align: center;
        flex: 0 0 9.3em;
        padding: .5em;

        .card__inner {
            justify-content: center;
            display: flex;
            align-items: center;
            height: 5.6rem;
            flex-direction: column;
        }

        .no-logos {
            height: 4em;
        }

        a {
            text-decoration: none;
        }

        figure {
            cursor: pointer;
            padding: .5em 0;

            .image {
                display: block;
                margin: auto;
            }

            .image, .error-image > svg {
                height: 3.5em;
                max-width: 100%;
            }

            .icon {
                position: absolute;
                right: 0.1em;
                top: 0.1em;
                opacity: 0.54;
            }

            figcaption {
                font-size: 0.9em;
                line-height: 1.2;
                word-break: break-word;
            }
        }

        &.selected {
            border: 1px solid #2196f3;
        }
    }
}
