.telesales {
    &.catalog-widget {
        height: 39em;
    
        .direct-buy__description {
            display: none;
        }
        
        .tab-control {
            margin-top: 0;
            order: 0;
        }

        .upper {
            justify-content: space-between;
        }

        .tk-parts {
            &.search-field {
                width: 24rem;
                height: 2.8rem;
                flex: unset;
                > .inputgroup {
                    height: 100%;
                }
            }
            .tree__node{
                height: 1.9em;
                &--xl {
                    height: 2.3em;
                }
            }
    
        }

    
        > .widget {
            > .widget__card {
                background-image: url(/styles/default/images/widget-bg-catalog.jpg) !important;
            }
            .widget {
                width: 100%; 
                height: 100%; 
                padding: 0;
                border-radius: initial;
                .widget__card {
                    padding: 0;
                    border: none;
                    background: initial;
                    box-shadow: none;
                }
                .widget__inner {
                    padding: 0.5em;
                }
                .widget__header {
                    display: none;
                }
                .widget__content {
                    > .label:first-child {
                        display: none;
                    }
                }
            }
    
        }
    
    }
}