.tk-parts {
    &.search-widget {
        .widget {
            &__card {
                background: unset;
                box-shadow: unset;
                border: unset;
                padding: 0.5em;
                display: flex;

                &:before {
                    content: "";
                    width: 0;
                    border: 2px solid rgba(0,0,0,0.15);
                    border-radius: 2px;
                }

            }

            &__content {
                padding-left: 16em;
                display: block;
            }
        }

        .intro {
            display: block;
            margin-bottom: 1em;
        }

        .search-field {
            margin-bottom: 1em;
        }

        .nodes {
            .btn {
                padding: 0.2em;
                margin: 0 0 0.2em 0.2em;
            }
        }

        .vehicle-parts {
            .text {
                margin: 1em 0;
            }
        }
    }
}
