.article-details {
    .layout__slot-1 {
        display: flex;
        flex-direction: column;

        .tab-control {
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
            margin-bottom: 2px;
        }
    }
}