.tk-parts {
    &.direct-search-widget {
        .text.label {
            &:after {
                content: ":";
            }
        }
        .widget {
            &__card {
                background: unset;
                box-shadow: unset;
                border: unset;
                padding: 0.5em;
                display: flex;
            }

            &__content {
                display: block;
            }
        }

        .search-field {
            margin-bottom: 1em;
        }

    }
}
