@import "./components/widget/index";
@import "./components/manufacturers/index";
@import "./components/modelseries/index";
@import "./components/models/index";
@import "./components/selections/index";
@import "./components/mainmodelseries/index";
@import "./components/searchfield/index";
@import "./components/details/index";
@import "./components/search/index";
@import "./components/filters/index";
@import "./components/search-tree-widget/index";
@import "./components/vrm-lookup-details-only/index";
@import "./components/request-vin/index";

.tk-vehicle {
    @import "./components/_shared/Dictionary";
    @import "./components/_shared/VrmLookupDetailsComponent";

    flex: 1;
}
.layout-vehicles {
    > .layout {
        > .layout__slot-0 {
            flex: 0 0 auto;
        }

        > .layout__slot-1 {
            align-items: initial;
            z-index: 0;
        }
    }

    .slot__vehicle_ordered_articles {
        margin: 0 0.5rem;
    }
}

.module.vehicle-details {
    > .layout {
        > .layout__slot-0 {
            flex-direction: column;
            align-items: stretch;
        }
    }
}

/* Find a better place for this */
#iframe_dat-registration {
    position: fixed !important;
    top: 0.5em !important;
    left: unset !important;
    right: 0.5em !important;
    height: calc(100% - 1em) !important;
    z-index: 24;
}

.print-vehicle-info-wrapper {
    position: relative;

    .badge {
        z-index: 1;
    }
    .print-vehicle-info-btn {
        margin-left: 0.25em;
    }
}

.printing-options {
    z-index: 1;

    .popover__inner {
        display: flex;
        flex-flow: column;
        > * {
            justify-content: space-between;
            padding-top: .2em;

            &:first-child {
                padding-top: 0;
            }
        }

        .print-vehicle-info-wrapper {
            display: flex;
            justify-content: right;
        }
    }
}
