.bundle-misc {
    &.iframe-module {
        width: 100%;
        overflow-y: hidden;
        position: relative;
        background: white;
        .close {
            position: absolute;
            right: 2em;
            top: 1.2em;
            z-index: 1;
        }
    }
}
