.price-table {
    .typeCell {
        flex: 0 0 18rem;
    }

    .quantityCell {
        flex: 0 0 6rem;
    }
    
    .priceCell {
        flex: 0 0 8rem;

        &--graduated {
            flex: 1 0 8rem;
            background-color: #e2e2e2;
            margin: -0.5rem -1.5rem -0.5rem 0;
            padding: 0.5rem 1rem;

            .graduated-prices__title {
                display: flex;
                align-content: center;

                .icon {
                    margin-right: 1rem;
                }
            }
        }
    }
    
    .vatCell {
        flex: 1 0 8rem;

        &--graduated {
            display: none;
        }
    }
    
    .rebateCell {
        flex: 0 0 8rem;

        &--graduated {
            display: none;
        }
    }
}