@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//Modal
    .modal__container.open {
        .modal {
            .article-details {
                &__head {
                    .article-details {
                        &__image {
                            &__thumbnail {
                                max-width: 15em;
                            }
                        }
                    } 
                }  
            }
        }
    }
//END Modal

    .selection-list {
        &__item--high-normal-priority {
            .text {
                max-width: 18em;
            }
        }
    }
    .tk-basket {
        &.parts-list {
            .part-list {
                &__item {
                    .thumbnail {
                        border-radius: 2px;
                        overflow: hidden;
                        background-color: #fafafa;
                        box-shadow: 0px 1px 5px rgba(0,0,0,0.2);
                        height: 4.5em !important;
                        display: flex ;
                        justify-content: center;
                        align-items: center;
                        img {
                            position: static !important;
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }
    .tk-parts {
        .article-list {
            .article {
                &__cell--thumbnail div
                {
                    border-radius: 2px;
                    overflow: hidden;
                    background-color: #fafafa;
                    box-shadow: 0px 1px 5px rgba(0,0,0,0.2);
                    height: 4.5em !important;
                }
                &__thumbnail {
                    height: auto !important;
                }
            }
        }
    }
}