.fancy-list__item {
    padding: 0.25rem;
}

.fancy-list__item {
    align-items: center;

    &:hover {
        .btn__icon {
            opacity: 0.87;
        }
    }
}

.fancy-list__block {
    max-height: 4em;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    &.plate {
        flex: 0 0 7rem;

        .plate-id {
            width: 100%;
        }
    }

    &.description {
        flex: 1 1 5rem;
    }

    &.vin {
        flex: 0 1 11rem;
        margin-right: 0;

        &:empty {
            display: none;
        }
    }
}
