.vehicle-status {
    &__key,
    &__value {
        width: 50%;
        opacity: .87;
    }

    &__nav {
        margin: 1em 1rem 0 1rem;

        .btn.is-active {
            background: #4b83c4;
        }
    }

    .fancy-list {
        margin: 0 1rem;
        &__head {
            color: #9a9a9a;
        }
    }
}
