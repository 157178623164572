@import "./components/navigation/index";
@import "./components/prices/index";
@import "./components/stock-info/index";
@import "./micros/combinations/erp-info/index";
@import "./micros/combinations/erp-info-details/index";
@import "./micros/pieces/details-erp-info-prices/index";
@import "./micros/pieces/erp-info-prices/index";
@import "./_shared/extended-delivery";
@import "./_shared/memo-tooltip-icon/index";

.dictionary {
    display: flex;
    flex-direction: column;

    &__item {
        display: flex;
    }

    &__key {
        &:after {
            content: ":";
        }
        margin-right: .2em;
    }

    &--row {
        flex-direction: row;
        flex-wrap: wrap;
        .dictionary__item {
            display: flex;
            margin-right: 0.5em;
            &:after {
                content: "|";
                margin-left: 0.5em;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.price__graduated__tooltip {
    display: flex;

    &__button {
        padding: 0;

        .icon {
            margin: 0;
        }
    }

    &__ab {
        text-align: left;
    }

    &__quantity {
        text-align: center;

        > div {
            padding-left: 0.4em;
            padding-right: 0.4em;
        }
    }

    &__price {
        text-align: right;
    }

    &--clickable {
        cursor: pointer;
    }
}

.erp-details__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid grey;
    padding-left: 1em;

    .icon {
        opacity: 1;
    }
}

.warehouse-quantities-table,
.simple-tour-table {
    line-height: 1rem;

    &__area {
        margin-bottom: 0.5em;
    }

    &__quantity {
        text-align: center;
    }

    &__separator {
        width: 1px;
        text-align: center;
    }

    &__description {
        text-align: left;
        white-space: nowrap;
    }
}

.warehouse-details {
    display: flex;

    &__item {
        margin-right: 1rem;
        padding-right: 1rem;
        border-right: 1px solid #fff;

        &:last-child {
            margin-right: 0;
            border-right: 0;
            padding-right: 0;
        }
    }
}

.warehouse-table-tooltip {
    margin: 0.5rem 0.25rem;
    max-height: 45vh;
    overflow-y: auto;

    .real-table {
        min-width: 25rem;
    }
}

.ek-tooltip-table {
    width: 100%;

    &__description {
        text-align: left;
        padding-right: 1rem;
    }

    &__price {
        width: 1px;
    }
}

.availability__standalone-image {
    max-height: 2em;
    max-width: 5em;
    margin-top: 2px;

    &.error-image svg {
        height: 2rem;
        width: 2rem;
    }
}

.warehouse-availability-images-with-badge {
    .availability__standalone-image {
        max-height: 2.5em;
    }
}
