.bundle-cis {
    margin-right: 1em;
    .next-tour {
        display: flex;
        cursor: pointer;
        border-radius: 3px;
        padding: 0;
        // height: 2.1em;
        align-items: stretch;

        .text--light {
            letter-spacing: 1px;
            font-size: 1.2em;
        }

        .time-box {
            position: relative;
            // height: 2em;
            padding: 0.3em 0.9em 0.3em 0.5em;
            border-radius: 3px;
            display: flex;
            align-items: center;

            &:before,
            &:after {
                content: '';
                position: absolute;
                right: 0;
                width: 0;
                height: 0;
                border-style: solid;
            }
            
            &:before {
                top: 0;
                border-width: 0 0.5em 1em 0;
            }
            
            &:after {
                bottom: 0;
                border-width: 1em 0.5em 0 0;
            }
        }

        // .icon {
        //     margin-left: 0.4em;
        //     margin-right: 0.25em;
        // }

        &__icon-container {
            display: flex;
            align-items: center;
            padding: 0 .3em;
        }

        &--wm {
            display: flex;
            align-items: stretch;
            flex-direction: column;
            cursor: pointer;
            text-align: right;
            padding: 0 0.5em;

            &__title {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                .text {
                    line-height: normal;
                    margin-left: 0.5em;
                }
            }
        }
        
        &.not-clickable {
            cursor: default;
        }
    }
}
