.vehicle-search {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;

    .loader {
        width: 3rem;
        height: 3rem;
        margin-left: calc(50% - 1.5rem);
    }

    .panel__title {
        margin-bottom: 0.625rem;
        font-size: 1.5rem;
    }

    .panel {
        margin: 0;
        // box-shadow: none;
        // border-bottom: 2px solid rgba(0, 0, 0, 0.2);

        &:last-child {
            border-bottom: 0;
        }

        &__content {
            padding: unset;
            overflow: hidden;
        }

        &__title {
            border-bottom: 0;
            padding: unset;
        }
    }

    .inputgroup {
        justify-content: center;
    }

    .suggest.inputgroup__children {
        flex: 0 1 38rem;

        // .suggest__box {
        //     width: 100%;
        //     margin-top: -0.5em;
        //     min-width: initial;
        //     max-width: initial;
        //     height: 15em;
        // }
    }

    &__search {
        .panel__content {
            overflow: visible;
        }
    }

    .manufacturers__list {
        margin: 0 -0.5em;
    }
}
