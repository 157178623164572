$spacing-S: 0.3rem;
$spacing-M: 0.5rem;
$spacing-L: 1rem;
$spacing-XL: 1.5rem;
$spacing-XXL: 4.5rem;

.module.tk-wheels > .layout > .layout__slot-1 {
    flex: 1;
}

.tk-wheels {
    &.tk-wheels_content {
        padding: 0 $spacing-M;
    }
    .tyres-list {
        .wheels__filters-wrapper {
            flex: 0 0 17%;
            margin: 0;
        }
    }
}

.configurator-dialog {
    .dialog-prompt__inner {
        width: 99%;
        height: 99%;
        display: flex;
        flex-direction: column;
        .dialog-prompt__additional-content{
            flex: 1;
        }
    }
}

@import "./components/navigation/index";
@import "./components/loading-screen/index";
@import "./components/vehicle-selection/index";
@import "./components/wheels-list/index";
@import "./components/wheels-details/index";
@import "./components/overview/index";

@import "./components/2d-configurator/index";

.wheels-image-zoom {
    .image {
        height: 85vh;
    }
}

.clickMaskContainer{
    display: none;
}