.product-groups {
    padding: .5em;

    .list__item {
        display: flex;
        justify-content: flex-start;
        flex-flow: column;
        min-width: 60em;
        margin-bottom: .3em;
    }

    // &__filter > *:first-child {}


    &__filter > *:last-child {
        flex: 1 0 3em;
    }

    &__filter > *:first-child {
        flex: .3 0 20em;
    }

    &__filter > * {
        flex: 0 0 24em;
        padding-left: .5em;
        align-self: center;
    }

    &__record-info {
        flex: 0 0 16em;
        .text {
            font-family: "roboto condensed";
        }
    }

    &__filter {
        display: flex;
        align-items: stretch;
        // border-left: 5px solid transparent;

        &__option {
            text-align: left;
            opacity: 1;
        }

        .dropdown {
            .btn__content {
                opacity: 0.87;
                text-align: left;
            }
        }
    }

    &__decline {
        display: flex;
        justify-content: flex-end;
    }

    .collapsible__header {
        &:hover{
            background: rgba(0, 0, 0, 0.05);
        }
    }
}

.vehicle-records{
    &__clear-all {
        position: relative;
        &-button {
            position: absolute;
            top: 0;
            right: 1em;
        }
    }

    &__fitting-side {
        cursor: pointer;
    }

    &__pg-fitting-side {
        position: relative;
        margin-bottom: 2em;

        &::before {
            content: "";
            position: absolute;
            /* background: rgba(0, 0, 0, 0.1); */
            width: 10px;
            height: 98%;
            border-radius: 3px;
            top: 1%;
            bottom: 1%;
            border: 4px solid rgba(0, 0, 0, 0.1);
            border-right: 0;
            border-top: 0;
            border-top-left-radius: 0;
        }
    }
}
