.bundle-misc {
    &.widget-concept-page {
        .image {
            width: 100%;
            height: 5em;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            transition: .2s ease all;
        }
        .widget__cover-text {
            padding-top: 0.5em;
        }
        .loader {
            position: absolute;
            left: 45%;
        }
    }
}
