.tk-telematics {
    flex: 1;

    @import "./components/analysis/index";
    @import "./components/chosen-vehicle/index";
    @import "./components/gps/index";
    @import "./components/inspection/index";
    @import "./components/maintenance/index";
    @import "./components/misc/index";
    @import "./components/vehicle-status/index";
    @import "./components/update-view-brand/index";
    @import "./components/providers/index";

    .box {
        &__headline {
            opacity: .87;
        }
    }

    .details {
        .title {
            padding: 1rem;
            border-bottom: 1px solid rgba(33,150,243,.32);
        }

        .row {
            margin-bottom: 3rem;

            .col {
                display: flex;
                margin-top: 1rem;
                height: 100%;

                &__left {
                    flex: 2;
                    margin: 0 1em;
                }

                &__center {
                    flex: 2;
                    margin: 0 1em;
                }

                &__right {
                    margin: 0 1em;
                    padding: 1.25em;
                    border-left: .4375em solid #dfe4e7;

                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .box {
            background-color: #ffffff;
            box-shadow: 0 1px 5px rgba(0,0,0,.2);
            padding: 1rem;
            height: 100%;

            &__headline {
                font-size: 1.5em;
                display: flex;
                align-items: center;
                margin-left: -1rem;
                margin-bottom: 1rem;

                &:before {
                    content: "";
                    display: block;
                    width: 1rem;
                    height: .5rem;
                    background: #2196f3;
                    margin-right: 1rem;
                }
            }
        }

        .list {
            display: flex;

            &__cell {
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                margin: 0 .5em;
                width: 100%;

                &:hover {
                    .minor-text {
                        opacity: .87;
                    }

                    .icon {
                        opacity: .87;
                    }
                }

                .list__cell__icon {
                    margin-bottom: 1rem;
                }

                .text.text--highlight {
                    margin-bottom: .5rem;
                }

                .text {
                    margin-bottom: .5em;
                }
            }
        }
    }
}
