.bnd-misc.tab-control {
    padding-bottom: 1em;
    .tab {
        margin-right: 3px;
    }
}

// wm widget startpage
.border-bottom {
    .bnd-misc.tab-control {
        border-bottom: 4px solid #666;
    }
}
