.tk-parts {
    &.search {
        .search-field {
            width: 25em;
            margin-top: .75em;

            .inputgroup {
                box-shadow: none;

                .tooltip {
                    > .btn {
                        height: 100%;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }

                .suggest{
                    &.article-search {
                        .input__inner {
                            .input__field {
                                width: 90%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.tk-parts.search-field {
    .inputgroup {
        .suggest.article-search {
            .suggest__box {
                max-height: 25em;
                overflow-y: scroll;
            }

            .fancy-list__item {
                white-space: normal;
                word-break: break-word;
            }
        }
    }
}
