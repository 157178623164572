$return-item-image-size: 4.5em;


.tk-cis {
    &.returns {
        flex: 1;
        padding: 0.5em;

        .header {
            display: flex;
            justify-content: space-between;
        }

        .return-summary {
            .totals{
                display: flex;
                align-items: center;
                height: 30px;
                
                .text {
                    margin-right: .5em;
                }
            }

            .button-container{
                display: flex;
                gap: 0.5rem;

                .print-button-wrapper{
                    position: relative;

                    .badge{
                        z-index: 1;
                    }

                    .print-return-items-button{
                    margin-right: 0.25em;
                    }
                }
            }
        }

        .part-list {
            display: flex;
            flex-direction: column;

            .list__item {
                margin-bottom: 1em;
            }

            &__properties {
                display: flex;
                flex: 1;
                flex-direction: row;

                .return-reason {
                    .btn__content {
                        white-space: pre-wrap;
                        width: 185px;
                    }
                }

                // stylelint-disable-next-line
                & > * {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                // stylelint-disable-next-line
                & > *:not(.thumbnail) {
                    position: relative;

                    // stylelint-disable-next-line
                    &:not(.no-separator)::after {
                        content: ' ';
                        display: block;
                        position: absolute;
                        height: 25%;
                        top: 0;
                        right: 0;
                        width: 1px;
                    }
                }
            }

            &__item {
                display: block;
                flex: 1;
                
                .part-list__properties {
                    .thumbnail {
                        flex: 0 0 $return-item-image-size;
                        img,
                        .wholesale-icon,
                        .error-image {
                            height: $return-item-image-size;
                            width: $return-item-image-size;
                        }

                        .wholesale-icon {
                            display: flex !important;
                            align-items: center;
                            justify-content: center;
    
                            .icon {
                                height: $return-item-image-size - 2.25em;
                                width: $return-item-image-size - 2.25em;

                                // making sure that the supplier icon is centered
                                margin-left: -.25em;
                            }
                        }
                    }

                    .manufacturer {
                        flex: 0 0 7.5em;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: center;
                    }

                    .numbers {
                        flex: 1;
                        align-items: flex-start;
                    }
                    
                    .description {
                        flex: 0 0 9em;        
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    
                    @media only screen and (min-width: 1500px) {
                        .description {
                            flex: 1;
                        }
                    }

                    .order-date, .order-number, .delivery-number, .invoice-number {
                        flex-direction: column;
                        flex:   0 0 7em;
                    }

                    .icon-column {
                        flex: 0 0 2.5em;
                    }
                   
                    .quantity {
                        flex: 0 0 5.5rem;
                    }

                    .state {
                        flex: 0 0 35px;
                        text-overflow: initial;
                    }

                    .released .switch {
                        flex-wrap: wrap;
                    }

                    .thumbnail {
                        position: relative;

                        img,
                        .wholesale-icon,
                        .error-image {
                            display: block;
                            object-fit: scale-down;
                            border-radius: 2px;
                            overflow: hidden;
                            user-select: none;
                            position: absolute;
                            left: -0.3em;
                            top: -0.2em;
                        }
                        .error-image svg {
                            width: 100%;
                            height: 100%;
                        }

                        &--clickable {
                            border: solid 1px transparent;
                            cursor: pointer;
                        }
                    }
                }
            }

            .parts-note {
                flex: 1;
                padding: 0.31em;
                margin: 0 .5em;
            }
        }

        .parts-selection {
            flex: 1;
            text-align: right;
            justify-content: flex-end;
            align-items: center;
            position: relative;

            .card {
                position: absolute;
                top: -10px;
                z-index: 1;
                right: 1em;
            }

            .card__inner {
                display: inline-flex;
                align-items: center;
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.54);
                padding-top: .7em;
                padding-bottom: .7em;
                height: 2.3em;

                .text {
                    padding-right: .2em;
                    white-space: nowrap;
                    
                    .numbers {
                        padding-right: .7em;
                    }
                }

                .selection {
                    margin: 1.2em;
                }

                .cancel {
                    margin-right: -1px;
                    width: 5em;
                    display: flex;
                    justify-content: center;
                }

                .status-switch {
                    padding: 0 1.15em;
                }
            }
        }

        .order-completed {
            &__message {
                text-align: center;
                padding: .5em;
                border-bottom: 3px solid #e2e2e2;
                position: relative;               

                .message-inline {
                    padding-top: .5em;
                    padding-bottom: .5em;
                    
                    &__icon {
                        align-self: center;
                    }
        
                    &__text-wrapper {
                        text-align: left;
                    }
                }

                .order-success {
                    display: flex;
                    flex-direction: column;
         
                    .card__inner {
                        padding: .5em;
                    }
        
                    &__details{
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: .5rem;
                    }
        
                    &__date{
                        border-top: 1px solid #e2e2e2;
                        text-align: left;
                        padding-top: .5em;
                    }
                }
            }

            &__text {
                padding: 1em 0;
                display: flex;
                flex-direction: column;

                .text {
                    padding: .5em;
                }

                .btn {
                    margin-left: .5em;
                }
            }            
        }       
    }
}