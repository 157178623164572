.tk-crm {
  &.customer-details {
    align-items: flex-start;
    flex: 0 0 auto;
    flex-wrap: wrap;

    .panel {
      padding: 0.5em;
      margin: 0.5em;
      flex: 1;
      width: auto;
    }
    .information {
        .panel__content {
        flex: 1;
        padding-bottom: 1em;
      }
      .flex-wrapper .input__field {
        line-height: 1.3;
      }
    }
    .field {
      padding: 0.5em;
      flex: 1;
    }

    .address-suggest-box {
      .zip {
        flex: 0 0 2rem;
      }
      .city {
        flex: 0 0 10rem;
      }
    }

    .suggest {      
      &__box {
          padding: 1em 0.5em 0.5em;
          margin-left: 0.25em;
          margin-top: 4em;
          width: 50rem;
      }
    }

    .btn-expand {
      position: absolute;
      top: auto;
      bottom: -1em;
    }

    .price-calculation {
      flex: 0 0 24em;
      .card__content {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
