.gps {
    height: 100%;
    .box {
        .box__content {
            .list {
                .list__cell {
                    .icon {
                        margin-bottom: 0;
                    }

                    .main-text {
                        font-size: 1em;
                        margin: 0;
                    }
                }
            }
        }

        .map {
            height: 27.6em;
            margin: -1rem -1rem 1rem;
            margin-bottom: 1rem;
        }
    }
}
