//TODO: Remove this file when Collapsible is available in tm/components
.tk-basket {
    &.order-options {
        &.order-options__collapsible {
            flex: 1;

            .collapsible__caption {
                flex: none;
            }
        }

        .dropdown {
            align-self: stretch;

            .btn {
                height: 100%;
                width: 13em;
            }
        }
    }
}
