$color-primary: #2196f3;
$color-primary-l10: #51adf6;
$color-light: #ffffff;
$color-dark-l16: #494949;

.tk-parts.filter-modal {
    display: flex;
    flex-direction: column;
    width: 100%;

    .filter-modal__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1em;
    }

    .filter-modal__content {
        flex: 1;
        padding-right: 17px; // width of the Chrome scrollbar
        overflow-y: scroll;
        overflow-y: overlay; // only works for Chrome
    }

    .filters {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, 33.33333%);
        grid-auto-rows: 1em;

        .filter {
            min-height: unset;
            max-height: unset;
            margin: 0;
            overflow: hidden;

            .collapsible {
                &__content {
                    -ms-scroll-chaining: unset;
                    overscroll-behavior: unset;
                }
            }
        }
    }
}
