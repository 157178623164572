.tk-standalone {
    &.rd-add-articles-to-basket {
        margin-right: .25em;
        position: relative;
        display: flex;
        .badge {
            z-index: 1;
        }
        
    }
}
