.tk-vehicle {
    @import "../_shared/ManufacturerList";

    &.vehicle-manufacturers {
        .loader {
            width: 3rem;
            height: 3rem;
            margin-left: calc(50% - 1.5rem);
        }
    }
}
