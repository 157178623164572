.brand-information {
    float:left;
    padding-right: 1em;
    a {
        text-decoration: none;
    }
    .tooltip {
        line-height: 0;

        &__content {
            white-space: nowrap;
        }
    }

    .brand-image {
        align-self: center;
        height: 2.5em;

        &.error-image {
            display: none;
        }
    }
}