.vehicle-selection-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    .selections {
        display: flex;
        margin-bottom: $spacing-M;

        .panel {
            &:first-child {
                flex: 0 0 33.33%;
            }

            margin-bottom: 0;

            &.selection-wrapper {
                margin-left: $spacing-M;
            }

            &.model-series {
                margin-left: $spacing-M;
                margin-right: $spacing-M;
            }

            img {
                width: 100%;
            }
        }
    }
    .error{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .vehicle-info-selections {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .disabled-table {
            .fancy-list__body {
                opacity: 0.54;
                user-select: none;
                pointer-events: none;
            }
        }

        .fancy-list__block {
            min-width: 8rem;
        }
    }

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
    }
}