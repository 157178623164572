.tk-crm {
    &.widget-start {

        .suggest--new .suggest__box {
            padding: 3.5em 0 0
        }

        .text.label {
            &:after {
                content: ":";
            }
        }

        .line {
            display: flex;
            width: 80%;
            margin: 1em auto;
            &:before, &:after {
                content: "";
                flex: 1;
                border-top: 2px solid #666;
                margin-top: 0.5em;
            }
            .text {
                margin: 0 0.5em;
            }
        }

        .widget__content {
            transition: all 0.2s ease;
        }

        .tooltip__wrapper {
            min-width: 20em;
        }

        .widget__content {
            .card__content {
                .subtitle {
                    opacity: .54;
                }

                .content__head {
                    margin-bottom: 2em;

                    // .no {
                    //     margin-right: .5em;
                    // }

                    .name {
                        margin-top: 5px;
                        display: block;
                    }

                    .customer__address {
                        padding-top: .5em;
                    }

                    .modified {
                        margin-top: 2em;
                    }
                }
            }
            .content__body {
                .details {
                    display: flex;

                    .detail {
                        width: 30%;
                        display: flex;
                        flex-direction: column;
                        border-left: solid 1px #c3c3c3;
                        padding: .1em .5em .25em .5em;

                        .value {
                            margin-top: .5em;
                        }

                        &.detail-small {
                            width: 15%;
                        }

                        &.pen-icon {
                            border: none;
                            opacity: .54;

                            &:hover {
                                opacity: .87;
                            }
                        }
                    }
                }
            }
        }

        .btn {
            display: block;
        }

        .inputgroup {
            .suggest {
                flex: 1;
                z-index: 3;
            }
            .btn {
                z-index: 0;
            }

            .fancy-list__block {
                margin: 0;
                padding-right: 1em;
                overflow: hidden;
                text-overflow: ellipsis;

                &.no {
                    //flex: 0 0 15%;
                    width: 15%;
                }
                &.name {
                    //flex: 0 0 26%;
                    width: 26%;
                }
                &.addr {
                    //flex: 0 0 27%;
                    width: 27%;
                }
                &.vehicle {
                    //flex: 0 0 27%;
                    width: 27%;
                }
                &.action {
                    //flex: 0 0 5%;
                    width: 5%;
                    padding: 0;
                }
                .plate {
                    margin-bottom: 0.25em;
                }
            }
        }

        // with customer
        .no {
            &:after {
                content: " ";
            }
        }
    }

    .btn-container {
        display: flex;
        justify-content: center;
        .btn {
            font-size: 0.875em;
            line-height: 1;
            .icon {
                height: 1.5em;
                width: 1.5em;
            }
            .btn__content {
                text-align: left;
            }
            small {
                font-size: 0.625rem;
                line-height: 1;
            }

        }
    }
}

