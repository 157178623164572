.tk-erp.navigation {
    width: 100%;

    margin: 0 0 0.9rem 0;
    display: flex;

    .navigation__item {
        flex: 1;
        text-align: center;

        border-right: 1px solid grey;
        border-radius: 0;
        padding: 0.2em;

        &:last-child {
            border-right: none;
        }
    }
}