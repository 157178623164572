$vertical-alignment-sidegap: 4em;
$components-background: transparent;

.tk-vehicle {
    .vrm-lookup-details--only {
        .panel {
            background: $components-background;
        }

        .panel__text {
            display: none;
        }
    }
}


.vrm-lookup-details-modal {
    padding: 2em;
    padding-top: $vertical-alignment-sidegap;

    >.layout {
        .layout__slot-0 {
            min-height: 35%;
        }
    }

    .widget--car {
        .special-license-plates__container {
            padding-top: 1.1em;
        }
    }

    .widget {
        padding: 0
    }


    .widget__card {
        border: 0;
        padding: 0;
        box-shadow: none;
        background: $components-background;
    }

    .widget__header {
        margin-bottom: 3em
    }

    .widget__content {
        padding-left: $vertical-alignment-sidegap;
    }

    .widget .widget__card:hover {
        box-shadow: none;
        background: $components-background;
    }

    .widget__inner {
        padding: 0;
    }

    .widget__title {
        font-size: 2em;
        padding-bottom: .5em;
    }

    .tk-vehicle.vrm-lookup-details-wrapper {
        padding-left: $vertical-alignment-sidegap;
    }
}
