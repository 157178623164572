.maintenance {
    flex: 1;

    &__items {
        display: flex;
    }

    &__item {
        display: flex;
        flex-direction: column;
        flex: 0 0 20%;
        position: relative;
        margin: 0 1em;
        padding: 1em 0;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 5px;
            width: 4rem;
            background: #c3c3c3;
        }
    }

    &__headline {
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 1em;
        opacity: .87;
    }

    &__icon {
        font-size: 2em;
        line-height: 1;
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        color: #2196f3;
        font-weight: bold;

        .icon {
            margin-left: 1rem;
        }
    }

    &__term {
        flex: 1;
        margin-bottom: 2em;
        opacity: .87;
    }

    &__text {
        font-size: 2em;
        color: #2196f3;
    }

    $options-width: 11em;
    $options-margin-top: -5px;
    $options-background-color: #ffffff;
    $options-border-color: #2196f3;
    $options-border-thickness: 1px;
    $options-arrow-width: 16px;
    $options-arrow-height: 16px;

    &__options {
        display: inline-block;
        position: relative;
        margin-left: 0.25em;
        color: #202020;
        transition: all .35s ease;
        cursor: pointer;

        &:hover {
            color: #c3c3c3;
        }

        &-dropdown {
            position: absolute;
            top: 100%;
            left: -50%;
            width: $options-width;
            margin-top: $options-arrow-height + $options-margin-top;
            border: solid $options-border-thickness $options-border-color;
            border-radius: 3px;
            z-index: 1;

            &-arrow {
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -(calc($options-arrow-width / 2)) + $options-border-thickness;
                margin-top: $options-margin-top;
                border-left: solid calc($options-arrow-width / 2) transparent;
                border-right: solid calc($options-arrow-width / 2) transparent;
                border-bottom: solid $options-arrow-height $options-border-color;
            }
        }
    }

    &__option {
        padding: 1em;
        font-size: 0.9rem;
        color: #202020;
        background-color: $options-background-color;
        transition: all .35s ease;
        cursor: pointer;

        &:hover {
            background-color: #b2dbfb;
        }
    }
}
