.repair-times {
    &__categories {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;

        &:only-child {
            flex: 1;
        }

        .node-list {
            /*height: 100%;*/
            flex: 1;

            &:not(:only-child) {
                margin-top: 0.5em;

                .fancy-list__head {
                    display: none;
                }
            }
        }
    }
}
