$margin: 4px; // use px instead of em to prevent decimal rounding errors

.oe-positions {
    margin: 1em;

    &__headline {
        margin-bottom: 0.5em;
    }

    &__replacements {
        margin-top: 0.5em;
    }

    &__number-wrapper {
        margin: $margin * -1;
    }

    &__number,
    &__replacement {
        margin: $margin !important; // !important is needed to overwrite .btn margin (sorry!)
    }
    &__replacement .oe-positions__number {
        margin: 0 !important; // number inside replacement does not need margin
    }

    &__replacement {
        display: inline-block;

        &::before {
            content: "\2192";
            display: inline-block;
            width: 15px;
            margin-right: $margin * 2;
        }

        &:first-child {
            &::before {
                content: "\2BA1";
                width: 14px; // Fix position of arrow
                padding-left: 1px; // Fix position of arrow
            }
        }
    }
}
