.tk-wheels__navigation {
    width: 100%;
    display: flex;
    position: relative;
    margin: $spacing-M 0 $spacing-M 0;

    .toolbar{
        margin-left: 1em;
    }

    .applyBtn--wrapper {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin-right: 1em;
    }
    
    .tk-parts.switch {
        .toolbar__inner {            
            .toolbar__title {
                padding: 0;
                margin-top: -0.25em;
                margin-bottom: 0;
            }
            .toolbar__content {
               .switch {
                    height: auto;
                }
            }
        }
    }

    .tk-parts.mounting-switch::before {
        border: none;
    }

    .steps {
        .text.text--l.step__head {
            line-height: 1.3;
        }
    }
}
