.update-view {
    margin-bottom: 1.875em;

    &__info,
    &__footnote {
        opacity: 0.87;
        margin: 6px 0;
        color: #494949;
    }

    &__last-update {
        display: inline-block;
        margin-left: 5px;
        font-size: 12px;
        white-space: pre;
    }

    &__footnote {
        display: flex;
        align-items: center;

        .icon {
            margin-left: 5px;
        }
    }
}

.brand {
    display: flex;

    &__logo {
        margin-right: 1.25em;
    }

    &__version {
        margin-left: .5em;
    }
}
