.repair-times {
    &__direct-search {
        display: flex;

        .input-field {
            > .input-field {
                width: 20em;
                .input__inner {
                    .input__field {
                        width: 90%;
                    }
                }
            }
        }

        .input__tooltip {
            .tooltip__content {
                width: 10em;
                white-space: pre-wrap;
            }
        }
    }
}
