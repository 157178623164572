.tk-wheels {
    .wheels__overview__page {
        flex: 1;
        .vehicle_info {
                .flex-wrapper {
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    position: relative;

                    .car_info {
                        padding: 1em;
                    }

                    .car_logo {
                        padding: 1em 0 0 0;
                        height: 17em;

                        img {
                            height: 100%;
                        }
                    }

                    .basket-elements {
                        display: flex;
                        position: absolute;
                        right: 0;
                    }
                }
        }
        .overview-logos {
            display: flex;
            flex-direction: column;
            align-items: center;
            .overview-logos-container {
                display: flex;
                justify-content: center;
                width: 45%;

                .logo {
                    margin: 0.5em;
                    width: 7em;
                    object-fit: contain;
                }
            }
        }
        .article__supplier-logo {
            max-height: 2rem;
            max-width: 5rem;
        }

        // wm only
        .tk-parts .article-list .tk-basket .add-to-basket {
            justify-content: space-between;

            div {
                display: flex;
            }
        }
    }
}
