.article-details__references {

    .panel__content {
        padding: 0.5em 1em;
    }

    &__button {
        margin: 0 .125rem .125rem 0;
        display: inline-block;

        .tag {
            width: 100%;
        }

        .tooltip {
            flex: 1;
        }

        .tag__content {
            flex: auto;
            width: 100%;
            justify-content: center;
        }
    }
}

.article-list__references {

    .layout--two-slot,
    .layout--one-slot {
        .layout__slot {
            height: 100%;
        }
    }

    .article-details__references {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
}

@media screen and (max-height: 757px) {
    .article-list__references {
        padding-bottom: 2.5em;
    }
}