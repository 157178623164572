@-moz-document url-prefix() {
    .tk-vehicle.filters .filters__modelyears .modelyears {
        max-height: 10em;
    }
}

@supports (-ms-ime-align:auto) {
    .tk-vehicle.filters .filters__modelyears .modelyears {
        max-height: 10em;
    }
}

.tk-vehicle {
    &.filters {
        .collapsible {
            display: flex;
            margin-right: 4px;
            height: 2.25em;

            &--disabled {
                .collapsible__header {
                    color: #ccc;
                    cursor: default;

                    > .icon {
                        fill: #ccc;
                    }
                }

                .btn--clip {
                    pointer-events: auto;
                }
            }

            &--opened:not(.collapsible--disabled) {
                height: auto;

                .collapsible__content {
                    flex: auto;
                    max-height: 10em;
                }

                .filter--clipped {
                    flex: 1;

                    &.filters__modelyears {
                        flex: .5
                    }
                }
            }
        }

        .filters {
            &__bodytypes {
                .collapsible__content {
                    width: 20em;
                }

                .bodytypes {
                    display: flex;
                    flex-wrap: wrap;

                    &__item {
                        flex: 0 0 5em;
                        padding: 0.5em;

                        &--disabled {
                            pointer-events: none;
                            opacity: 0.54;
                        }

                        &--selected,
                        &:hover {
                            cursor: pointer;
                        }

                        img {
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }

            &__modelyears {
                .collapsible__content {
                    width: 18em;
                }

                .modelyears {
                    display: flex;
                    flex-wrap: wrap;

                    &__item {
                        flex: 0 0 4em;
                        padding: 0.5em;
                        text-align: center;

                        &--selected,
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

            }

            &__filter {
                .collapsible__content {
                    width: unset;
                }

                .filter {
                    &__item {
                        padding: 0.5em;

                        &--selected,
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        &--clipped {
            flex: 0 0 22rem;
            align-self: flex-start;
            position: sticky;
            top: 10.7em;

            .toolbar__inner {
                flex: 1;
                padding: 0.5em;
            }

            .toolbar__content {
                flex-direction: column;
                align-items: stretch;
            }
        }

        .filter {
            &--clipped {
                flex-direction: column;
                height: unset;
                margin: 0 0 4px;
                min-height: 2em;

                &.filters__bodytypes,
                &.filters__modelyears {
                    .collapsible__content {
                        width: unset;
                    }
                }

                .collapsible__header {
                    flex: 0 0 auto;
                }

                .collapsible__content {
                    flex: 1;
                    overflow: auto;
                    overflow-x: hidden;
                }
            }
        }
    }
}
