.tk-compilations {
  &.compilation-details {
    flex: 1;
    padding: 1rem 0 1rem 1rem;
    max-width: calc(100% - 25rem);

    &.wm {
      max-width: 100%;
    }


    .part-editor {
      .input__label {
        transition: all .2s ease;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95%;
      }

      .content {
        display: flex;
        align-items: center;
        padding: 0.5rem;

        > .input,
        > .inputgroup {
          flex: 1;
          margin-left: 0.5rem;
        }

        > .amount-field {
          flex: 0 0 4.4rem;
          margin-left: 0.5rem;
        }

        .compilation-creator__buttons {
          margin-left: 0.5rem;
        }
      }
    }

    .part-list {
      margin-top: 1.5rem;

      .list__item {
        margin-bottom: 1em;
      }

      &__header {
        display: flex;
        justify-content: space-between;

        &__left {
          display: flex;
        }

        .headline,
        .text {
          padding-right: .7em;
        }
      }

      &__properties {
        display: flex;
        flex: 1;
        flex-direction: row;

        // stylelint-disable-next-line
        & > *:not(.price) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        // stylelint-disable-next-line
        & > *:not(.thumbnail) {
          position: relative;

          // stylelint-disable-next-line
          &:not(.no-separator)::after {
            content: ' ';
            display: block;
            position: absolute;
            height: 25%;
            top: 0;
            right: 0;
            width: 1px;
          }
        }
      }

      &__table {
        display: flex;
        flex-direction: column;
      }

      &__item {
        display: block;
        flex: 1;

        .manufacturer {
          flex: 0 0 8em;

          .text {
            display: -webkit-box;
            max-width: 200px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        .thumbnail {
          flex: 0 0 2.5rem;
          position: relative;

          img,
          .error-image {
            height: 4.5rem;
            width: 4.5rem;
            display: block;
            object-fit: scale-down;
            border-radius: 2px;
            overflow: hidden;
            user-select: none;
          }

          .error-image svg {
            width: 100%;
            height: 100%;
          }

          &--clickable {
            border: solid 1px transparent;
            cursor: pointer;
          }
        }

        .customer-number {
          flex: 0 0 11rem;
          justify-content: flex-start;
        }

        .description {
          flex: 1;
          flex-direction: column;
          align-items: flex-start;
        }

        .note {
          padding-right: 0;
        }

        .actions {
          .checkbox {
            margin-left: .5rem;
          }
        }

        .alternatives {
          cursor: pointer;
        }

        .avail {
          flex: 0 0 9em;
        }

        .price {
          flex-direction: column;
          flex: 0 0 7.6rem;
          position: relative;
          display: flex;
          padding-top: 1.5rem;

          &:not(.no-separator) {
            .price__value {
              padding-left: .5em;
            }
          }

          &:first-child::after {
            display: none;
          }

          &.sum {
            flex: 0 0 6.5rem;
          }


          & + .price {
            padding-left: 0;
          }

          &__type {
            position: absolute;
            top: .5em;
            left: .5em;
          }
        }

        .rebate {
          flex: 0 0 3.5rem;
        }

        .quantity {
          flex: 0 0 5.5rem;
        }

        .state {
          flex: 0 0 35px;
          text-overflow: initial;
        }

        .released .switch {
          flex-wrap: wrap;
        }
      }

      .parts-note {
        display: flex;
        align-items: center;
        padding: .6em 0 .3em 0;
        margin: 0 .5em;

        .input--textfield {
          flex: 1;
          padding: 0.31em;
          margin: 0 .5em;
        }
      }
    }

    .parts-selection {
      .card__inner {
        display: inline-flex;
        align-items: center;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.54);
        border-color: #82c4f8;
      }

      flex: 1;
      text-align: right;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      .card {
        position: absolute;
        top: 15px;
        z-index: 5;
        right: 0
      }

      .card__inner {
        display: inline-flex;
        align-items: center;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.54);

        .dynamic-actions {
          display: flex;

          &.switch {
            flex-flow: row-reverse;
          }
        }

        .text {
          padding-right: .2em;

          .numbers {
            padding-right: .7em;            
            overflow-y: auto;
             max-height: 100px;
          }
        }

        .selection {
          margin: 1.2em;
          color: #9b9b9b;
         
        }

        .cancel {
          margin-left: .3em;
          width: 3em;
          display: flex;
          justify-content: center;
        }

        .delete-button {
          margin-right: .7em;
        }

        .move-dropdown {
          padding: 0 .5rem;
        }

        .dropdown {
          min-width: 15rem;

          svg {
            margin-left: .5rem
          }

          .btn__content {
            text-align: right;
          }
        }
      }
    }

    .empty-list {
      & > * {
        align-items: center;
        padding: 1em .5em;
        display: flex;
        flex: 1;
      }

      .icon {
        align-self: center;
        margin-right: 1em;
      }

      .text {
        margin-right: 1em;
      }

      &__dashboard-button {
        .btn {
          display: inline-flex
        }
      }
    }

    .total {
      padding: .5em;
      display: flex;
      flex: 1;

      .label {
        flex: 1;
        text-align: right;
      }

      .price {
        flex: 0 0 6rem;
        text-align: right;
      }

      .loader {
        margin: 0 0 0 auto;
      }
    }
  }
}
