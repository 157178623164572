.wminfo_bgrpnl{

    letter-spacing: 0px;

    & div.wminfo_outer{
        max-width: 1920px;
        background-color: #EEEEEE;
        display: flex;
        padding: 24px 0 40px 0;

        & div.wminfo_inner{
            flex-shrink: 0;
            max-width: 1920px;
            width: 100%;
            color: #333;

            & div.wminfo_headline{
                color: #009866;
                font-size: 22px;
                margin-left: 1%;
                margin-bottom: 20px;
                font-family: Helvetica,Arial
            }

            & div.wminfo_text{
                flex-shrink: 0;
                font-size: 14px;
                margin:0px 1% 0px;
                font-family: Arial;
                overflow:auto;
                text-align: justify;
                line-height: 20px;
            }
        }
    }
}