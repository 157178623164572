.article-details__head {
    height: 14em;
    margin-bottom: 1em;

    &--loader {
        .panel__content {
            align-items: center;
            justify-content: center;
        }

        .headline {
            margin-right: 0.5em;
        }

        .loader {
            height: 2rem;
            width: 2rem;
        }
    }

    .btn-compilations {
        margin-left: .25rem;
    }

    .panel__content {
        display: flex;
        width: 100%;

        > div {
            max-height: 100%;
        }

        .article-details {
            &__image {
                max-height: 13.4em;
                height: 13.4em;
            }
        }
    }

    .article-details {
        &__image {
            flex: 1;

            display: flex;
            min-width: 16em;
            margin-right: 2em;
            justify-content: center;
            align-items: center;

            .tooltip {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;

                &__wrapper {
                    z-index: 30;
                }

                &__content {
                    min-width: 16em;
                    min-height: 22em;
                    width: 35em;
                    height: 22em;
                }
            }

            &__thumbnail {
                max-height: 100%;
                max-width: 100%;
                object-fit: contain;
                object-position: center;

                &--clickable {
                    cursor: pointer;
                }
            }

            .image-viewer {
                width: 100%;
                height: 100%;
            }

            &__icon {
                position: absolute;
                width: 3rem;
                height: 3rem;
                background-color: #a4a4a4;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
                border-radius: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                right: 10px;
                bottom: 10px;

                .icon {
                    fill: #fafafa;
                }
            }
        }

        &__info {
            &__row {
                &__thumbnail {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    margin-right: .3rem;
                    padding: .1rem;
                    flex: 0 0 84px;

                    .image {
                        max-height: 100%;
                        max-width: 100%;
                        object-fit: contain;
                        object-position: center;
                        cursor: pointer;
                    }

                    .error-image svg {
                        max-height: 60px;
                        max-width: 60px;
                    }
                }
            }

            &__item {
                margin-right: 2rem;
            }
        }

        &__bonus-system {
            display: flex;
            align-items: center;
            border-left: solid 1px grey;
            padding: 0 1em;

            .image {
                width: 6em;
                height: auto;
            }
        }

        &__actions {
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 1rem;

            &__erp,
            &__basket {
                display: flex;
                align-self: flex-end;
                margin-right: 2rem;
            }

            &__availability-overview {
                margin: .7em 2em .7em 3.25em;
                align-self: flex-end;
            }

            .tooltip--dark {
                .tooltip__content {
                    color: #fff;
                }
            }
            .tooltip__content {
                min-width: 0;
            }
            .standalone-basket-elements {
                display: flex;
                .amount-field {
                    margin-right: .25em;
                }
            }
        }

        &__supplier-pg {
            display: flex;
            align-items: center;
        }

        &__supplier-logo {
            max-height: 2rem;
            max-width: 5rem;

            &.error-image svg {
                height: 2rem;
                width: 2rem;
            }
        }
    }

    .cost-estimation-btn {
        min-width: 3em;
        display: flex;
        justify-content: center;
        margin-left: 0.25em;
    }
}

.article-list__item {
    .print-details-wrapper {
        position: relative;
        display: inline-block;

        .badge {
            z-index: 1;
        }
        .print-details-btn {
            margin-left: 0.25em;
        }
    }
}

// modal view
.modal {
    .article-details__head {
        .article-details {
            &__info {
                &__item {
                    margin-right: 0;

                }
            }
        }
    }
}