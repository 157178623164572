.tk-crm {
    &.vehicle-info{
        display: flex;
        flex-direction: column;
        align-items: center;

        .plate-id {
            margin: .3rem
        }
    }
}