.article-list__item--oe {   
    .oe-prices {
        &__price {
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            &:not(:last-child) {
                margin-bottom: 0.25em;
            }

            &__description {
                margin-right: 1em;
            }
        }
    }

    .article__cell--attributes {
        &.loading {
            display: flex;
            margin-right: 0;
            margin-left: 0;
            align-items: center;
            justify-content: center;
        }
    }
    .article__cell {
        &--description {
            min-width: 13em;
        }

        &--erp-information {
            min-width: 19em;
        }
    }
}

.list.searchtreeV2 {
    .article-list__item--oe {    
        .article__cell {   
            &--actions {
                min-width: 26em;
                
                .more-menu {
                    margin-left: auto;
                }
            }
        }
    }
}

.oe-item-actions {
    .add-to-basket__button {
        margin-left: .25em;
        margin-right: 1em;
    }
    min-width: 26.5em;
}

.oe-item-thumbnail {
    margin-right: 1em;
    
    .article__thumbnail.image {
        width: 3em;
        height: 3em;
        margin-right: 1em;
    }
}