.tk-parts {
    .dropdown.is-active .dropdown__box {
        z-index: 2000
    }
    &.navigation {
        flex: 0 0 auto;

        &:not(.navigation--dropdown) {
            .toolbar__content {
                display: flex;
                margin: -1em -0.5em;
            }

            .area-buttons--holo {
                padding: 0 0.5em;
            }

            .btn--ghost {
                padding: 0.25em;
                height: 1.75rem;
            }
        }

        &.navigation--dropdown {
            .btn {
                &:last-child() {
                    margin-right: 0;
                }

                & + .btn {
                    // margin-left: 0;
                }
            }

            .dropdown {
                .btn__content {
                    order: 1;
                }

                a {
                    text-decoration: none;
                }

                &__item {
                    padding: 0.65em 0.5em;
                }

                .dropdown__box {
                    width: auto;
                }
            }

            .page-buttons {
                display: inline-block;
            }
        }
        .toolbar__content{
            height: 2em;
        }
    }
}
