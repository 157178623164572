.frame {
    height: 100%;
    width: 100%;
    flex: 1 0 auto;

    .loader {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: 20%;
        left: 50%;
        margin-left: -1.5rem;
    }
}
.close-haynespro {
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 101;
    -webkit-transform: translateX(-1em);
    transform: translateX(-1em);
}

.mvc-frame {
    .layout__slot {
        height: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
    }
}

#iframe_haynespro-rtgenarts,
#iframe_autodata-rtgenarts,
#iframe_tecrmi-rtgenarts,
#iframe_haynespro-td-genarts,
#iframe_tecrmi-td-genarts,
#iframe_article-feedback {
    z-index: 25;
}