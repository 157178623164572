.worktask-modules {
    flex: 1;
    overflow: hidden;
    position: relative;

    &__inner {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__wrapper {
        display: flex;
        flex: 1;
        position: relative;
        padding: 0 0.5em;
        // margin: 0 -0.125em;
        overflow: hidden;

        a {
            text-decoration: none;
        }

        .more {
            &__btn {
                visibility: hidden;
                pointer-events: none;
                font-weight: bold;

                &.is-visible {
                    visibility: unset;
                    pointer-events: unset;
                }
            }

            &__list {
                position: absolute;
                right: 0;
                top: 2.5em;
                z-index: 2;

                .tab__content {
                    flex-direction: column;
                    align-items: flex-start;
                    flex: 1;
                    min-width: 10em;
                }
            }

            &__item {
                padding: 0.2em;
                display: flex;
                align-items: center;
                text-decoration: none;
                background: unset;

                &:hover {
                    background: #ddd;
                }

                .tab__close {
                    padding: 0;
                }
            }
            &__wrapper {
                align-items: center;
                display: flex;
            }
        }
    }
}

.more__list {
    .more__item {
        padding: 0.2em;
        display: flex;
        align-items: center;
        text-decoration: none;

        &:hover {
            background: #ddd;
        }

        .tab__content {
            flex: 1;
            justify-content: flex-start;
        }

        .tab__close {
            padding: 0;

            .icon {
                display: block;
            }
        }
    }
}
