.module.navigation .layout__slot.layout__slot-0.multiwidget__head .widget--customer {
    .content__body .details {
        display: flex;

        .btn:not(.btn--icon-only) .icon {
            width: 1.5em;
            height: 1.5em;
        }

        .detail {
            flex: 1;
            width: auto;
            display: flex;
            flex-direction: column;
            border-left: solid 1px #c3c3c3;
            padding: 0.1em 0.5em 0.25em 0.5em;

            .value {
                line-height: 1.2;
                margin-top: 0.5em;
                overflow: hidden;
                word-wrap: break-word;
            }

            &.detail-small {
                flex: 0 0 3em;
            }

            &.pen-icon {
                border: none;
                opacity: 0.54;

                &:hover {
                    opacity: 0.87;
                }
            }
        }
    }
}
.tk-crm {
    &.widget {
        &.is-disabled {
            opacity: .54;
            pointer-events: none;
        }
        &.is-editing {
            .widget__header,
            .widget__content {
                filter: blur(5px);
            }
        }
        &.tiny-widget {
            // suggestbox expand hack
            .suggest__box {
                width: auto;
                padding: 0;
                margin-top: 2.3em;
                min-width: 33rem;
            }
            .details--phone {
                min-width: 7.5rem;
            }
            .details--email {
                width: 8rem !important;

                .text {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .widget__footer {
                .footer-wrapper {
                    align-items: center;
                    
                    .btn {
                        font-size: 10px;
                        height: 24px;
        
                        .icon {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }
        }

        .tooltip__wrapper {
            min-width: 20em;
        }
        .card__intro {
            display: flex;
            margin: 1em 0 1.5em;
            justify-content: center;

            &__text {
                text-align: right;
                margin-right: 1em;
            }
        }
        .widget {
            &__header {
                margin-bottom: 0.5em;

                .btn {
                    &--top-right {
                        position: absolute;
                        right: 1rem;
                        top: 1rem;
                        padding: 0;

                        .icon {
                            width: 1.5em;
                            height: 1.5em;
                        }
                    }
                }
            }
            &__content {
                transition: all 0.2s ease;
                .card__content {
                    
                    .content__head {
                        margin-bottom: 0.5em;

                        &--height {
                            min-height: 6.5rem;
                        }

                        .no {
                            margin-right: 0.5em;
                        }

                        .customer__address {
                            padding-top: 0.2em;
                        }

                        .modified {
                            margin-top: 0.5em;
                        }
                    }
                }
                .content__body {
                    .details {
                        display: flex;

                        .btn:not(.btn--icon-only) .icon {
                            width: 1.5em;
                            height: 1.5em;
                        }

                        .btn {
                            &--top-right {
                                position: absolute;
                                right: 1rem;
                                top: 1rem;
                                padding: 0;
                            }
                        }

                        .detail {
                            flex: 1;
                            width: auto;
                            display: flex;
                            flex-direction: column;
                            border-left: solid 1px #c3c3c3;
                            padding: 0.1em 0.5em 0.25em 0.5em;

                            .value {
                                line-height: 1.2;
                                margin-top: 0.5em;
                                overflow: hidden;
                                word-wrap: break-word;
                            }

                            &.detail-small {
                                flex: 0 0 3em;
                            }

                            &.pen-icon {
                                border: none;
                                opacity: 0.54;

                                &:hover {
                                    opacity: 0.87;
                                }
                            }
                        }
                    }
                }
            }
            &__footer {
                margin-top: -0.2em;
                .footer-wrapper {
                    display: flex;
                }
            }
        }

        .btn {
            display: inline-flex;
        }

        .inputgroup {
            .suggest {
                flex: 1;
                z-index: 3;
                
                .scrollbar__view {
                    height: 100%;
                    overflow-x: hidden !important;
                }
            }

            .btn {
                z-index: 0;
            }

            .fancy-list__block {
                margin: 0;
                padding-right: 1em;
                overflow: hidden;
                text-overflow: ellipsis;

                &.no {
                    //flex: 0 0 15%;
                    width: 15%;
                }
                &.name {
                    //flex: 0 0 26%;
                    width: 26%;
                }
                &.addr {
                    //flex: 0 0 27%;
                    width: 27%;
                }
                &.vehicle {
                    //flex: 0 0 27%;
                    width: 27%;
                }
                &.action {
                    //flex: 0 0 5%;
                    width: 5%;
                    padding: 0;
                }
                .plate {
                    margin-bottom: 0.25em;
                }
            }
        }

        // with customer
        .no {
            &:after {
                content: " ";
            }
        }
    }    
}
