.vrm-lookup-details {
    .panel {
        &__title {
            padding-bottom: 1em;
            border: none;
        }

        &__content {
            padding-bottom: 1em;

            .data-requestable--centered {
                display: flex;
                align-items: center;
            }
        }

        &__text {
            display: flex;
            flex: 1;
        }
    }

    &__group {
        .fancy-list__body {
            max-height: 25em;
            overflow-y: auto;
            @media print {
                max-height: initial !important;
                overflow-y: initial !important;
            }
        }

        &:not(:last-child) {
            margin-bottom: 1em;
        }

        &--general,
        &--additional {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }

        &--color,
        &--equipment {
            .fancy-list__block {
                &.large {
                    flex: 1;
                }

                &.small {
                    flex: 0 0 15rem;
                }
            }
        }

        &--equipment {
            .headline {
                display: flex;
                align-items: center;
            }

            .input {
                width: 25rem;
                margin-left: 0.5em;

                .btn {
                    padding: 0; // Fix different height with and without clear btn
                }
            }
        }
    }
}
