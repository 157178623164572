.tk-crm {
    &.customer-vehicles {
        .vehicle-list {
            display: flex;
            flex-wrap: wrap;
        }
        .card {
            flex: 0 0 25%;
            padding: 0.5em;
            min-height: 10em;
            display: flex;
            &__inner {
                padding: 0.5em;
                display: flex;
                flex-direction: column;
                flex: 1;
            }
            &--add {
                .card__inner {
                    display: flex;
                    background: transparent;
                    box-shadow: none;
                    border: 2px dashed #c3c3c3;
                    transition: all .35s ease;
                }
                a {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    text-decoration: none;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                }
            }
            &:hover {
                .card__inner {
                    background: #fff;
                    border-style: solid;
                }
            }
            &__actions {
                position: absolute;
                top: .5em;
                right: .5em;
                .btn {
                    font-size: 0.8em;
                    white-space: nowrap;
                }
            }
            &--vehicle {
                .card__inner {
                    display: flex;
                    flex-direction: column;
                }
                &__image {
                    height: 5em;
                    position: relative;
                    display: block;
                    svg {
                        width: auto;
                        max-width: 80px;
                        margin-top: -10px;
                    }
                }
            }
            &--note {
                display: flex;
                flex-direction: column;
                .card__footer {
                    position: absolute;
                    bottom: 0.5em;
                    right: 0.5em;
                }
                .card__content {
                    .input {
                        height: 100%;
                        background: transparent;
                        &__inner {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
}
