.details .row .providers.col__right {
    flex: 1;
    display: block;

    .provider-logo {
        max-height: 6em;
        margin-top: .2em;
    }

    .header-wrapper {
        display: flex;
        flex: 100%;
        align-items: center;
        margin-bottom: 1em;

        .icon {
            margin-right: .3em;
        }
    }

    .providers__logo-wrapper {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
    }
}
