.tk-parts {
    &.uni-search-widget {
        .upper {
            display: flex;
        }

        .tip-wrapper {
            margin-left: 3rem;
            pointer-events: none;
            .badge {
                position: relative;
                width: 1rem;
                top: auto;
                right: auto;
                display: inline-block;
            }
        }

        .text.label {
            &:after {
                content: ":";
            }
        }
        .tree {
            flex: 1;
        }
        .widget {
            &__card {
                background: unset;
                box-shadow: unset;
                border: unset;
                padding: 0.5em;
                display: flex;
            }

            &__content {
                display: flex;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        .search-field {
            margin-bottom: 1em;
            flex: 1;
        }

        .nodes {
            .btn {
                padding: 0.2em;
                margin: 0 0 0.2em 0.2em;
            }
        }
    }
}

