.analysis {
    margin-top: .5rem;

    .box {
        &__content.analysis {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: .5rem;

            .rating {
                font-size: 2em;
                opacity: .87;
            }

            .chart {
                margin: 0 2em 0 1em;
                width: 10em;
                height: 3.5em;
            }

            .description {
                opacity: .87;
                color: #2196f3;
                font-size: 1.2em;
                font-weight: 700;
            }

            img {
                opacity: .87;
            }
        }
    }
}
