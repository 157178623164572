.tk-parts {
    &.parts-widget {
        &.vehicleParts, &.universalParts, &.aiSearch {
            .widget__content {
                overflow: hidden;
            }
        }

        .widget {
            &__inner {
                // padding-bottom: 0;

                .widget__content {
                    flex: 1;

                    .upper {
                        display: flex;
                        align-items: center;

                        .badge-wrapper {
                            pointer-events: none;
                            display: inline-block;
                        }

                        .tip-wrapper {
                            display: flex;
                            align-items: baseline;

                            .caption {
                                white-space: pre;
                            }
                        }

                        .badge {
                            position: static;
                        }
                    }

                    .lower {
                        padding-top: 1em;
                        position: relative;

                        .tip {
                            display: flex;
                            align-items: center;
                        }
                        .history {
                            overflow: hidden;
                            max-height: 100px;
                        }
                    }
                }
            }

            &__footer:empty {
                display: none;
            }
        }

        .card__navigation {
            display: flex;
        }

        .tree {
            max-width: 100%;

            &__node--m {
                height: 1.9em;
            }
        }

        .category-selection {
            z-index: 2; // must be at least 1 bigger than then .search-field z-index so that it will overlay the search field
            overflow: hidden;
        }

        .search-field {
            z-index: 1; // must be a z-index > 0 so that the suggest dropdown will overlay any following content
            max-width: 660px;
       }

        .history {
            top: 4em;
            position: absolute;
            left: 0;

            .subtitle {
                margin-bottom: 1em;
            }
            .btn {
                padding: 0.2em;
                margin: 0 0.2em 0.2em 0;
            }
        }
    }
}
