.compilations  {
    &__list {
        max-height: 9em;
        overflow: hidden;
        overflow-y: auto;
        margin: 0.5em 0;
    }

    &__item {
        display: flex;
        align-items: center;
        width: 17.2em;
        min-height: 2em;
        cursor: pointer;

        &.isChecked {
            pointer-events: none;
        }

        &:hover {
            background-color: #efefef;
        }

        .state {
            padding-left: 0.5em;
            flex: 0 0 2.2em;

            .checkbox {
                padding: .5rem .2rem;
            }
        }
        .text {
            padding: 0 .5em 0;
            flex: 1 0 16em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .icon {
            display: block;
            height: 1.2em;
            width: 1.2em;
            opacity: 0.87;
        }
        .loader {
            height: 1.2em;
            width: 1.2em;
        }

    }
}

.create-compilation {
    padding: 0.3em 0.5em 0.3em 0;
    display: flex;
    .input {
        flex: 1;
        margin-left: 0.5em;
    }
    .btn {
        font-size: inherit;
        margin: 0 0 0 2px;
        &.create {
            margin: 0;
            .icon {
                width: 1em;
                height: 1em;
            }
        }
    }
}