.tk-wheels {
    .wheels-details {
        display: flex;
        flex: 1;

        .rim-details {
            padding: $spacing-M;
            flex: 1;

            .panel__content {
                height: 100%;
                display: flex;
                flex-direction: column;  

                .rim-details__items {
                    flex: 1;

                    .scrollbar__view {
                        .axle-group {
                            cursor: auto;
                            margin-bottom: 0.5em;

                            &--hidden{
                                visibility: hidden;
                            }
                        }
                        .article-list {
                            padding:.5em 0em !important;
                            overflow: hidden;
                            .article__cell{
                                &--prefix{
                                   *{display: none;}
                                }
                            }
                        }
                    }
                }
            }
        }

        .rim-information {
            padding: $spacing-M;
            margin-left: $spacing-M;
            width: calc(100% - 78em);
            min-width: 23.5em;
            // flex-basis: 20%;
            .panel__content {
                height: 100%;
                display: flex;
                flex-direction: column;

                .tabs {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-bottom: $spacing-M;
                }

                .wheels-technical-data {
                    display: block;
                    .title {
                        margin-bottom: $spacing-M;
                    }
                    .pdf-btn {
                        margin-bottom: $spacing-M;
                    }
                    .list {
                        &__item {
                            justify-content: space-between;
                        }
                    }
                }

                .mounting-pads {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .mounting-pads-tabs {
                        margin-bottom: $spacing-M;
                    }
                    .restrictions {
                        flex: 1;

                        .list {
                            padding: 0;
                            &__item {
                                flex-direction: column;
                                padding: $spacing-M;
                                justify-content: space-between;
                            }
                        }
                    }
                }

                .tire-sizes-table {
                    flex: 1;

                    .fancy-list {
                        &__head,
                        &__item {
                            justify-content: space-between;

                            .fancy-list__block:nth-child(1) {
                                flex:1;
                            }
                            .fancy-list__block:nth-child(2),
                            .fancy-list__block:nth-child(3),
                            .fancy-list__block:nth-child(4),
                            .fancy-list__block:nth-child(5)  {
                                flex: 1;
                                display: flex;
                                justify-content: center;
                            }
                            .fancy-list__block{
                               word-wrap: break-word;
                               margin-right: .5em;
                            }
                        }
                    }
                    .fancy-list__head {
                        .fancy-list__block:last-child {
                            visibility: hidden;
                        }
                        align-items: center;
                    }
                    .fancy-list__body {
                        .fancy-list__block:nth-child(2),
                        .fancy-list__block:nth-child(3),
                        .fancy-list__block:nth-child(4),
                        .fancy-list__block:nth-child(5)   {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}
