.modal__body > .layoutwrapper{
    display: flex;
    flex: 1;
    padding: 1em;
}

.tk-repair-times__repair-times-request {
    flex: 1;
    display: flex;
    flex-wrap: wrap;

    ul li {
        padding-bottom: .5em;
    }

    .panel {
        width: 50%;

        &--stretch {
            width: 100%;
        }
    }

    .input__field.input__field--multiline {
        height: 10em;
    }
    .button-wrapper {
        display: flex;
        flex: 1 1 100%;
        padding-right: 3em;
        padding-top: 1em;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
    }

    .panel__title {
        border: 0;
        padding: 0 0 .625em
    }
}
