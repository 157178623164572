.tk-basket {
    &.direct-buy {                        
        .amount-field {
            button {
                border-radius: 0;
                height: 100%;
            }
        }
            
        &.add_button {
            padding: 0.5em 1.5rem;
            border-radius:  0 3px 3px 0;
            margin-right: 1em;

            .icon {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        &.article_number {
            border-radius: 3px 0 0 3px;
            width: 16em;
            // height: 3rem;
            margin-right: 0;

            .input__inner {
                height: 100%;
            }
        }

    }
}  
        
.direct-buy__article-details{
    z-index: 19; // The modals are not added as pushed, therofre the z-index is not increasing correctly

    .tk-basket .add-to-basket {
        .btn__icon {
            height: 2em;
        }
    }    
}