$cis-part-image-size: 4.5em;

.tk-cis {
    &.parts-list.select-articles {
        flex: 1;
        align-self: stretch;
        padding-top: 0.5em;

        .select-articles__header {
            display: flex;
            margin-top: .5em;

            .content {
                flex: 1;
            }            
        }

        .part-list {
            &__properties {
                display: flex;
                flex: 1;
                flex-direction: row;
                flex-wrap: wrap;

                & > * {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                & > *:not(.thumbnail) {
                    position: relative;

                    &:not(.no-separator)::after {
                        content: ' ';
                        display: block;
                        position: absolute;
                        height: 25%;
                        top: 0;
                        right: 0;
                        width: 1px;
                    }
                }
            }

            &__item {
                display: flex;
                flex: 1;
                flex-direction: column;

                .manufacturer {
                    flex: 0 0 8em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                }

                .numbers {
                    flex: 0 0 8rem;
                    align-items: flex-start;
                }

                .thumbnail {
                    flex: 0 0 $cis-part-image-size;
                    position: relative;

                    img,
                    .wholesale-icon,
                    .error-image {
                        height: $cis-part-image-size;
                        width: $cis-part-image-size;
                        display: block;
                        object-fit: scale-down;
                        border-radius: 2px;
                        overflow: hidden;
                        user-select: none;
                        position: absolute;
                        left: 0.5em;
                        top: -0.5em;
                    }

                    .error-image svg {
                        width: 100%;
                        height: 100%;
                    }

                    &--clickable {
                        border: solid 1px transparent;
                        cursor: pointer;
                    }
                }

                .description {
                    flex: 1 0 10em;
                    justify-content: flex-start;
                }
            }
        }
    }
}
