.article-comparision {
    margin-left: .5em;
    min-width: 100%;

    &.items-2 {
        .article-comparision-item {
            flex: 0 0 49%;
        }
    }

    @media only screen and (min-width: 1280px) {
        &.items-3 {
            .article-comparision-item {
                flex: 0 0 32.33%;
            }
        }
    }

    &-item {
        @media only screen and (min-width: 1281px) { flex: 0 0 29%; }

        &__general-information{
            .message-inline {
                padding:.2em;
                margin: 0;

                &__text-wrapper {
                    margin-left: 0;
                }
           }
        }

        &__detailed-information {
            .panel {
                padding: .5em 1em;;
            }

            &__vehicle {
                position: relative;
                display: inline-flex;
                margin-top: 1.5em;

                &:before {
                    position: absolute;
                    content: "";
                    width: 3px;
                    background: #f8d182; // will be overwriten in styles
                    height: 100%;
                    left: -1em;
                }
            }

            .key-value-list--attributes {
                .tag__value {
                    white-space: pre-wrap;
                }
            }

            .article-comparison__references {
                .panel {
                    box-shadow: none;
                    font-size: xx-small;
                    margin: 0;
                    padding: 0;
                }

                .panel__title {
                    padding-left: 0;
                }

                .panel__text {
                    font-size: small;
                }
                .panel__content {
                    .headline {
                        font-size: small;
                    }
                }
            }
        }

        &__images {
            .image-viewer__image-wrapper {
                height: 250px;
            }
        }

        &__bonus-system {
            margin-right: 0.5em;

            .image {
                display: block;
                width: 6em;
                max-height: 32px;
            }
        }

        &__micros {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;

            &-body {
                display: flex;
                align-content: flex-end;
                width: 100%;

                .tk-erp {
                    width: 100%;

                    .erp-info {
                        justify-content: flex-end;
                        position: relative;

                        .erp-info__prices {
                            margin-right: 0;
                            border-right: none;
                            flex: 0 0 100%;
                            justify-content: flex-end;

                            .price__group {
                                margin-right: 0;
                            }
                        }

                        .loader {
                            position: absolute;
                            left: 45%;
                            top: 2rem;
                        }

                        .not-available {
                            display: none;
                        }
                    }
                }
            }
        }

        .list {
            padding: 0;
            margin-left: -1em;
            margin-right: -1em;

            &__item {
                box-shadow: none;
                margin-bottom: 0;
                padding: 0.5em 1.25em;
                display: flex;
                justify-content: space-between;

                &:nth-child(odd) {
                    background-color: #EAEAEA;
                }

                .key-value-list {
                    &__key-cell{
                        flex: 0 0 50%;
                        width: 50%;
                        word-break: break-word;
                        padding-right: .2em;

                        .text {
                            font-size: .875rem;
                        }
                    }

                    &__value-cell {
                        flex: 0 0 50%;
                        width: 50%;
                        word-break: break-word;
                        display: flex;
                        flex-direction: column;

                        .tag__content {
                            margin: -.25em;
                        }
                        .article__attribute {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
