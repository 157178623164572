.btn-compilations {
  margin-left: .25rem;
}

.more-menu-wrapper {
        position: relative;
        display: inline-block;

        .badge {
            z-index: 1;
        }
    }