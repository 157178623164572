.tk-erp {
    .erp-info-details {
        @import "../../../_shared/availability-wrapper";
        display: flex;
        margin: .5em 0em;

        @import "../../../_shared/prices";

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            border-left: 1px solid grey;
            padding: 0 1em;
        }

        &__prices {
            display: flex;
            align-items: flex-end;
            min-height: 3rem;

            &:last-child {
                margin-right: 0;
            }

            .erp-info-details__staggered {
                border-right: 1px solid gray;
                padding-right: 1em;
                margin-right: 1em;
                height: 100%;

                display: flex;
                align-items: center;

                &__disabled {
                    visibility: hidden;
                }

                &__clickable {
                    cursor: pointer;
                }

                .btn {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        &__availability {
            display: flex;
            align-items: center;
            min-height: 3rem;
            margin-top: 0.7rem;

            &__disabled {
                visibility: hidden;
            }

            &--no-alternatives {
                justify-content: center;
            }

            .erp-info-details__alternatives {
                border-right: 1px solid gray;
                padding-right: 1em;
                margin-right: 1em;
                height: 100%;

                display: flex;
                align-items: center;

                &__disabled {
                    display: none;
                }

                &__clickable {
                    cursor: pointer;
                }

                .btn {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        &__warehouses {
            max-height: 15rem;
            overflow-y: auto;
            padding-right: 0.3rem;

            .real-table__cell {
                padding: 0.3rem 0.5rem 0.3rem 0;

                &:first-child {
                    padding-left: 0.5rem;
                }
            }
        }

        .loader {
            margin: 0 0.25em;
        }
    }
}

/* modifies the popover in the modal details header for coparts and hess,
   to prevent horizontal srollbar */
.article-details__head {
    .erp-info__availability__wrapper {
        .popover {
            left: -24%;
            .popover__arrow {
                width: 2rem;
                left: 18.7rem;
            }
        }
    }
}
