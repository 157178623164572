.tk-worktask {
    &.welcome {

        .headline {
            margin-bottom: 0.5em;
        }

        .widget {
            &__content {
                align-items: flex-start;
                display: block;
                flex-basis: initial;
                .text {
                    display: block;
                    margin-bottom: 1em;
                }
            }
            &__footer {
                margin: 0;
            }
        }
    }
}