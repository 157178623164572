.worktask-search {
    overflow: hidden;  
    width: 100%;
    display: none;

    .card {
        padding: 0;
        height: 100%;
        max-width: 50em;
        opacity: 0;
        position: relative;
        transform: translateX(-100%);
        transition: transform 200ms ease-out, opacity 200ms ease;
        width: 100%;

        &__inner {
            border-radius: 0;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 1rem;
        }
    }

    .header {
        display: flex;
        position: relative;
        margin-top: -0.5rem;
    }

    .headline {
        padding-right: 1rem;
        align-items: center;
        display: flex;
    }
    .input-search {
        width: 23rem;
        height: 2rem;
        .loader {
            position: absolute;
            top: 5px;
            right: 8px;
            width: 22px;
            height: 22px;
        }
    }
    .btn-search {
        height: 2rem;
    }
    .order-toggle {
        .switch {
            height: 2rem;
        }
    }

    .close {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateX(1.3rem);
    }

    &__inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        z-index: 1;

        .btn {
            font-size: .8em;
            line-height: 1em;
            margin-right: .1em;
            min-height: initial;
            padding: .3em;
        }
    }

    &--open {
        background: rgba(0,0,0,0.3);
        position: fixed;
        z-index: 20;
        top: 0px;
        bottom: 0px;
        left: 0px;
        display: block;

        .card {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .fancy-list {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 1em;
        overflow: auto;

        &__group {
            margin-right: 1rem;
        }
        &__head {
            flex: 0 0 auto;
            align-items: flex-end;
            padding: 0.5rem;
            > * {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__body {
            flex: 1;
            padding-right: 0.25rem;
        }

        &__item {
            cursor: pointer;
            padding: 0.5rem;
        }

        .voucher {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 8rem;
        }
        .numbers {
            flex: 0 0 8rem;
        }
        .order-status {
            width: 32px;
        }
        
        .total-numbers {
            flex: 0 0 3.5em;
            margin: 0;
        }

        .total {
            display: flex;
            flex-direction: row;
            align-items: center;
            .badge {
                position: inherit;
            }
            .icon {
                margin-right: 0.1em;
            }
            .loader {
                margin: 0;
            }
        }

        .details {
            flex: 1;
        }
    
        .creation-info {
            flex: 0 0 4rem;
        }

        .actions {
            display: flex;
            flex: 0 0 66px;
            margin-right: 5px;
            justify-content: flex-end;
        }
    
    }


    &-btn {
        height: 3rem;
        width: 3rem;
        margin: 0 0.2em;
        .icon {
            margin: auto;
            width: 1.5rem;
            height: 1.5rem;
        }

        &.has-text {
            height: auto;
            width: auto;
            flex-flow: column;
        }
    }

    .panel__title {
        margin-right: 1em;
        display: flex;
        align-items: center;
    }
    .panel__headline {
        margin-right: 1em;
    }
}
