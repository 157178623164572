@keyframes fadeOut  {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

// custom parts and works in the collpasible header
.tk-basket {
    &.cost-estimation__collabsible--small-caption .collapsible__caption {
        flex: 0;
        white-space: nowrap;
    }
}
