.tk-basket {
    .add-to-basket {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: space-between;
        margin-right: .2em;

        .badge {
            z-index: 1;
        }
        .dropdown {
            margin-right: .2em;
        }

        &__button {
            min-width: 6em;
            justify-content: center;
            position: relative;
            display: inline-flex;

            .icon {
                margin: 0;
            }

            .btn__content {
                opacity: 1;

                .btn__text {
                    margin-left: 0.6em;
                }
            }

            &__state {
                position: absolute;
                top: -0.4rem;
                right: 0.4rem;
                min-width: 1rem;
                height: 1rem;
                padding: 2px;
                font-size: 0.8rem;
                line-height: 0.8rem;
                border-radius: 0.5rem;

                &:empty {
                    display: none;
                }
            }
        }

        &--disabled {
            opacity: 0.54;
            pointer-events: none;
        }
    }
}
