.bnd-wm {
    &.tyres-widget {
        .field {
            &-container {
                display: flex;
            }
            flex: 0 0 25%;
            padding: 0.5em;
            display: flex;
        }
        .button-container {
            display: flex;
            padding: 0.5em;
            .btn {
                margin: auto;
            }
        }
        .dropdown {
            flex: 1;
            &__item {
                text-align: left;
            }
            .btn {
                height: 100%;
                .dropdown__item {
                    background: unset;
                    color: unset;
                }
            }
        }
    }
}