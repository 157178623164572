@import "./components/category-selection/index";
@import "./components/repair-times-selection/index";
@import "./components/direct-search/index";
@import "./components/provider-dropdown/index";
@import "./components/request-repair-times/index";

.repair-times {
    > .layout.layout--two-slot > .layout__slot-1,
    > .layout.layout--three-slot > .layout__slot-2 {
        flex: 1 0 auto;
        position: relative;
    }

    &__tree-selection {
        padding: 0 0.5em 0.5em;

        .layout__slot {
            &-0 {
                display: flex;
                flex-direction: column;
                background-color: white; //$color-light;
                padding: 0.5em 0.5em 0;
                margin-right: 0.25em;
                border-radius: 3px; //$global-radius;
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2); //$global-boxshadow;
                min-height: 15em;
            }

            &-1 {
                min-height: 15em;
            }
        }

        > .layout {
            & > .layout__slot {
                &-0 {
                    &:empty {
                        display: none;
                    }
                }

                &-1:empty {
                    flex: 0;
                }
            }
        }
    }

    .fancy-list {
        &__block {
            &--number {
                flex: 0 0 7rem;
            }

            &--description {
                flex: 1;
            }

            &--time {
                flex: 0 0 5rem;
                text-align: right;
            }

            &--actions {
                flex: 0 0 8rem;
                display: flex;
                justify-content: flex-end;
                margin-right: 0;
                > .btn{
                    margin-left: .2em;
                }
                > .badge {
                    z-index: 1;
                    top: 0.2em;
                    right: 1.5em;
                }
            }

            &--expand-button {
                flex: 0 0 1.6rem;
            }

            &--expendable {
                flex: 100%;
                display: none;
            }
        }

        &__item {
            &--expanded {
                flex-wrap: wrap;

                .fancy-list__block--expendable {
                    display: block;
                }
            }
        }

        &--scrollable {
            .fancy-list__item:first-child {
                margin-top: 2px;
            }
        }
    }
}

.part-repairtimes {
    .repair-times__selection {
        flex: 0 0 auto;
    }
    
    .bdl-parts.part {
        margin-bottom: 0.5rem;
    }
}