.catalogsFrame{
    flex: 0 0 50%;
}

.catalogsContent {
    width: 100%;
    background: url(https://test.wmkat.de/Content/img/next/Blaetterkataloge_320x240px_NEU.JPG);
    background-color: #7898B1;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 180px;
}

.text_catalogs {
    background-color: #1F1F1F;
    background-color: rgba(51, 51, 51, 0.8);
    width: 100%;
    bottom: 0;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    height: 40px;
    margin-bottom: 10px;
    color: white;
    font-size: 14px;
    display: inline-block;
}

