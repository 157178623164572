.standard-configurator {
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex: 1;

    .loader {
        width: 6em;
        height: 6em;
        position: absolute;
        top: 33%;
    }

    .standard-configurator-container {
        position: relative;
        pointer-events: none;
        margin: 0 auto;
        display: block;

        .image {
            position: absolute;
            pointer-events: none;
        }

        .front-rim,
        .back-rim,
        .front-brake,
        .back-brake,
        .front-loader,
        .back-loader {
            width: 0px;
            display: none;
            position: absolute;
            bottom: 0;
        }
    }
}
