.tk-erp.stock-info {
    .stock-info {
        @import "../../_shared/availability-wrapper";
    }

    flex: 1;

    .panel {
        margin-bottom: 0;
        box-shadow: none;
        padding-bottom: 1rem;

        &__content {
            padding: 0.3em 0.625em 0.5em 0.625em;
        }
    }

    .headline {
        margin-bottom: 1rem;
    }

    .fancy-list {
        &__head {
            justify-content: space-between;
        }
        &__block {
            align-self: center;
            //overflow: hidden;
            white-space: nowrap;
        }
        &__item {
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    .stock-info__teccom,
    .stock-info__warehouses {
        .warehouseCell,
        .availabilityCell {
            width: 20rem;
            padding-right: 5rem;
        }
    }

    .stock-info__teccom,
    .stock-info__prices {
        @import "../../_shared/price-table";
    }

    .stock-info__memos {
        padding-top: 1rem;
    }

    .stock-info__teccom {
        &__availability {
            margin-top: 1rem;
        }

        .amount-field {
            margin-right: 0.5rem;
        }
    }

    .stock-info {
        &__additional-infos {
            &__memo-table {
                .key {
                    width: 1px;
                    padding-right: 5rem;
                }
            }
        }
    }
}