.bundle-misc {
    &.widget-navigation-dropdown {
        cursor: pointer;

        .widget {
            &__inner {
                padding: 0.5em;
            }

            &__header {
                font-size: 1em;
                margin: 0.5rem 0 0;
                display: flex;
                flex-direction: column;
            }

            &__title {
                flex-direction: column;
                align-items: center;
                .text {
                    line-height: 1.1em;
                    margin-top: 0.1em;
                    text-align: center;
                }
            }

            &__footer {
                display: none;
            }

            &__icon {
                margin: 0;
            }

            &__content {
                text-align: center;
                position: relative;
                height: 100%;
                padding: 0;
            }
        }

        &__provider-text {
            font-size: 1rem;
            text-align: left;
        }

        &.is-disabled {
            opacity: 0.37;
            cursor: default;

            .widget__card {
                opacity: 1;
            }

            .widget__cover {
                cursor: default;
            }
        }

        &.has-activatable-modules {
            .dropdown__item {
                position: relative;
            }

            .navigation-item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .demo-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}
