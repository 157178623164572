.article-details__overview {
    width: 100%;

    .article-details__additional-infos {
        border: none;

        p {
            margin: 0.5em 0;
        }
    }

    .article-details__attributes {
        .panel__title {
            display: flex;
        }
    }

    .article-details__downloads {
        padding: 0.5em 1em;

        .tooltip {
            display: inline-block;

            &__content {
                padding: 0;
                min-width: 15em;
                min-height: 5em;
                width: 30em;
                height: 10em;
            }

            iframe {
                display: block;
                width: 100%;
                height: 100%;
                // pointer-events: none; // Scrolling inside the iFrame was not possible
                user-select: none;
            }

            & + .tooltip {
                margin-left: 5px;
            }
        }
    }

    .marketplace-price {
        .panel--inline {
            box-shadow: none;
            margin: 0;
            padding: 0;
            .panel__title {
                border: 0
            }
        }
    }
}

.article-details__content--stocks .layout__slot > .tk-parts {
    flex: 1;
}

.article-list__overview {
    .layout--two-slot,
    .layout--one-slot {
        height: inherit;

        .layout__slot {
            height: 100%;
        }
    }

    .article-details__overview {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        min-height: 215px;
    }
}

@media screen and (max-height: 757px) {
    .article-list__overview {
        padding-bottom: 2.5em;
    }
}

.article-list__vehicles {
    .layout__slot.layout__slot-1 {
        flex: 1 1 auto;
        height: calc(100% - 5.1em);

        .tk-vehicle {
            .article-references-model-series {
                height: 100%;
                overflow-y: auto;

                .modelseries {
                    margin-top: 1em;
                    margin-bottom: 1.5em;
                }
            }

            .article-references-types {
                height: 100%;
                width: 100%;
                overflow-y: auto;
            }
        }
    }
}

@media screen and (max-height: 757px) {
    .article-list__vehicles .layout__slot.layout__slot-1 .tk-vehicle  {
        .article-references-model-series,
        .article-references-types {
            padding-bottom: 2.5em;

            .modelseries {
                margin-bottom: 4em;
            }
        }
    }
}
