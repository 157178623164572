.tk-crm {
    .customer-info {
        padding: 0.2rem;
        margin-bottom: 1.3em;

        &__title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin: 0.2rem;
        }

        &__row {
            display: flex;

            .input {
                flex: 1;
                margin: 0.2rem;

                &.customerNo, &.addressAddition {
                    flex: 2;
                }
            }

            .customer-info__col {
                display: flex;
                flex: 1;
            }
        }

        .btn-expand {
            position: absolute;
            top: auto;
            bottom: -1em;
        }

        .panel__content {
          padding-bottom: 1em;
        }
    }
}