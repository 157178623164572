
.tk-parts {
    .list {
        z-index: 0;
        padding: 16px 0 0 16px;

        .cost-estimation-btn {
            width: 3em;
            display: flex;
            justify-content: center;
        }
        .article {
            &__cell {
                position: relative;

                &--prefix {
                    .btn--disabled {
                        visibility: hidden; // As in DES-1522 discussed the lower opacity is not that stating so hide the btn if it is disabled
                    }
                }
                &--description {
                    .article__missing-vehicle-connection {
                        border-left: 1px solid gray;
                        margin-left: 0.5em;
                        padding: 0.125em 0 0 0.5em;
                    }
                }
                &--icon {
                    .btn__content {
                        line-height: 0;

                        .image {
                            max-height: 1.2em;
                        }
                    }

                    .hit-info-icon-wrapper {
                        line-height: 0;
                    }
                }
                &--messages {
                    flex: 100%;
                    flex-wrap: wrap;
                    margin: 0 0.5em 0 3em;

                    > * {
                        margin-bottom: 0.25em;
                    }
                }
                &--supplier {
                    .article__supplier-logo {
                        max-height: 2rem;
                        max-width: 5rem;

                        &.error-image svg {
                            height: 2rem;
                            width: 2rem;
                        }
                    }
                }
                &--thumbnail{
                    svg{
                        width: 3em;
                        height: auto;
                    }
                }
            }

            &__bonus-system {
                $width: 4.5em;
                $height: 2em;
                $borderRadius: 3px;

                width: $width;
                height: $height;

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                &:not(:only-child) {
                    flex: 0 0 calc(#{$height} + #{$borderRadius});
                    width: calc(#{$height} + #{$borderRadius});
                    height: $width;
                    margin-right: -$borderRadius;
                    padding-right: $borderRadius;
                    overflow: hidden;
                    background-color: white;
                    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
                    border-radius: $borderRadius;

                    .image {
                        width: $width;
                        height: $height;
                        transform: rotate(-90deg) translateX(-100%);
                        transform-origin: top left;
                    }
                }
            }

            &__information-group {
                &__description {
                    .image {
                        max-height: 1.2em;
                        margin-right: 0.5em;
                    }
                }
            }

            &-list{
                .wholesaler-item {
                    .erp-info-prices {
                        padding-left: 1em;
                        border-left: 1px solid #1a1919;
                        border-right: 1px solid #1a1919;
                    }

                    .erp-info__availability__wrapper {
                        margin-right: 3em;
                        padding-left: 1.5em;
                        border-left: 1px solid #1a1919;
                        min-width: 9em;
                    }

                    .erp-info-alternatives  {
                        justify-content: center;
                        display: flex;
                    }

                    .alternatives {
                        width: 5em;
                    }

                    .alternatives-offers {
                        width: 3.1em;
                    }
                }
            }
        }

        &--with-bonus-systems {
            .article__cell--thumbnail {
                flex: 0 0 6.5em;
                justify-content: flex-end;
            }
        }
    }
    // TODO dropdown with label inside scssssss
    .article-group {
        margin-bottom: 16px;
        &__description {
            cursor: default;
            padding: 0;

            &-content{
                margin-bottom: 0.5em;
            }

            &__left {
                padding-top: 0.5em;
            }
        }

        &__filter-area {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            padding: 0.25em;
            margin-bottom: 0.25em;
            border: 3px solid rgba(194, 203, 210, 0.2);
            border-radius: 3px; // $global-radius;

            &:empty {
                display: none;
            }

            &__container {
                display: flex;
            }

            .xor__label {
                cursor: pointer;
            }

            .dropdown .icon {
                margin: -.25em;
            }
        }

        &:not(.article-group--open) {
            .article-group {
                &__description-content {
                    border-bottom: 2px solid rgba(#c2cbd2, .2); //$color-primary-l25-de75
                }
                &__filter-area {
                    display: none;
                }
            }
        }

        .article-list {
            margin: 0 0px;
        }
    }

    .filter-area__options {
        &:not(:first-child) {
            flex: 1;
            margin-top: 0.5rem;

            .toolbar__content {
                flex-wrap: wrap;
                margin-bottom: -0.2em;
            }

            .product-group-filter-selection {
                margin: 0 0.2em 0.2em 0;
            }
        }
        .toolbar__content {
            display: flex;
            align-items: flex-start;
        }
        &::before {
            content: initial;
            height: 35%;
            position: absolute;
            align-self: center;
            margin-top: initial;
        }
        &--leading {
            .toolbar__title {
                opacity: 0.54;
            }
            .toolbar__inner {
                align-self: flex-start;
            }
            .switch {
                margin: 0 .5em;
                &__content {
                    margin: initial
                }
            }
        }
    }
    .filter-area__reset-container {
        flex: 1;
    }

    .btn.filter-area__reset {
        padding: .5em;

        .btn__icon.icon {
            width: 1.5em;
            height: 1.5em;
        }
    }
}

.part-replacement {
    &__actions {
        display: flex;
        align-items: center;
        padding: 0.2rem 1rem;

        &__change {
            display: flex;
            align-items: center;

            .dropdown {
                margin-left: 1rem;
                min-width: 10rem;
            }
        }
    }

    &__header {
        border-bottom-width: 0.5rem;
        border-bottom-style: solid;
        padding: 0.5rem 1rem 4.5rem 1rem;

        display: flex;
        align-items: center;
        justify-content: space-between;

        &__left {
            display: flex;
            align-items: center;

            .icon {
                margin-right: 1rem;
            }
        }
    }

    > .article-list {
        margin: -4.5rem 2rem 0 2rem;
    }
    > .article-list__panel {
        margin: -3rem 2rem 0 2rem;
    }
}

.accessory-list-vehicle-descriptions {
    &__icon-wrapper {
        line-height: 0;
    }
    &__list-wrapper {
        max-height: 15rem;
        overflow-y: scroll;
    }
}

.product-group-filter {
    &-selection {
        .btn {

            &__content {
                padding-right: initial;
                opacity: initial;
            }

            &.is-active {
                * .text--sub {
                    opacity: .54;
                }
            }
        }

        .text {
            text-align: left;
            color: inherit;
            &:empty() {
                display: none;
            }
        }

        .popover__inner {
            max-height: 27rem;
            overflow-y: auto;
            overflow: auto;
        }

        &-container {
            margin-left: .5em;
        }

        .dropdown__item {
            padding: 0;
            margin-bottom: .5em;
            &:first-child {
                margin-top: .2em;
            }
        }

        .filter-option {
            display: flex;
            cursor: pointer;

            .tag__content {
                flex: 1;
                justify-content: center;
            }

            &--user-defined {
                position: relative;
                .tag__content::before {
                    content: '';
                    background: rgba(255,255,255, 0.36);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0%;
                    left: 0;
                    border-radius: 3px;
                }
            }

            &--recorded {
                position: relative;
                &::before {
                    content: '';
                    background: rgba(255,255,255, 0.66);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0%;
                    left: 0;
                    border-radius: 3px;
                }
            }
        }
    }
}

// temporary after consultation with EL, because there currently is nothing to display, when the article isn't compact
// if/when the mainarticle database functions have been extended, this can be deleted
.article-list__mainpartslist,
.article-list__mainaccessories {
    .article-list {
        .article__cell--prefix {
            .icon {
                display: none;
            }
        }
    }
}

.article-list__partslist {
    .list {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .scrollbar__view {
            .article-list {
                padding-right: 1em;
            }
        }

        @media screen and (max-height: 700px) {
            .scrollbar__view {
                margin-bottom: 1.5em !important;
            }
        }
    }
}

.tk-parts.article__tooltip {
    .tooltip__wrapper {
        z-index: 30;
    }

    .tooltip__content {
        width: 60em;
        height: 40em;
    }

    .image-viewer {
    width: 100%;
    height: 100%;
    }
}

.article-details__content {

    .panel {
        margin-bottom: 0;
    }

    .tk-parts .list {
        padding-left: 0;
        padding-right: 0;
    }

    &.article-list__partslist {
        .tk-parts {
            flex: 1;
            height: 100%;
        }
    }

    &.article-details__content--stocks {
        height: 100%;

        .tk-parts {
            flex: 1;
            height: 100%;

            .list {
                height: 100%;
                // overflow-y: auto;
            }
        }

        .tk-erp {
            &.stock-info {
                height: 100%;
                overflow-y: auto;
                padding-bottom: 2.5em;
            }
        }
    }

    @media screen and (max-height: 757px) {
        &.article-details__content--stocks {
            padding-bottom: 2.5em;
        }
    }

    &.article-list__accessories {

        .layout.layout--two-slot {
            .layout__slot.layout__slot-0 {
                height: 100%;
            }
        }

        .tk-parts {
            flex: 1;
            height: 100%;

            .list {
                height: 100%;
                overflow-y: auto;

                .article-list__panel.article-list__status {
                    height: calc(100% - 1.5rem);
                }
            }
        }
        .panel {
            margin: 0;
            padding-right: 0.2em;

            .panel__content {
                height: calc(100% - 2.5em);
            }
        }
    }

    @media screen and (max-height: 757px) {
        &.article-list__accessories {
            padding-bottom: 2.5em;
        }
    }
}

.article-list__no-result {
    padding: 1em;

    &__filters {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        >.labeled-dropdown {
            margin-right: 0.5rem;
            display: flex;
        }

        .btn {
            margin: 0;
        }
    }
}

@media screen and (max-height: 757px) {
    .article-list__no-result {
        padding: 0 1em;
    }
}

.article__reference {
    user-select: all;
}
