.tk-vehicle {
    .modelseries {
        margin-top: 1em;

        .loader {
            width: 3rem;
            height: 3rem;
            margin-left: calc(50% - 1.5rem);
        }

        &__header {
            display: flex;
        }

        &__headline {
            margin: 0 1rem 0.5rem 0.5rem;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -.5em;
        }

        &__item {
            flex: 0 0 16em;
            padding: 0.5em;

            a {
                text-decoration: none;
            }

            figure {
                // @extend .card;
                cursor: pointer;
                padding: 1em;
                height: 100%;
                img {
                    display: block;
                    margin: auto;
                    max-height: 4em;
                    max-width: 100%;
                }
                .error-image {
                    svg {
                        height: 4em;
                        width: 100%;
                    }
                }
            }

            figcaption {
                margin-top: 1em;
                // @extend .subtitle;
                font-weight: 600;
                text-align: center;
            }

            .construction-dates {
                margin-top: .5em;
                text-align: center;
                font-size: .9rem;
            }
        }
    }
}
