.bundle-misc {
    &.image-widget {
        img {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
        .widget__footer {
            display: none;
        }
    }
}

.error-image {
    width: 100%;
    height: 100%;
}

.article-comparision-item {
    .error-image {
        width: 60px;
        height: 60px;
    }
}