.tk-birner.elekat-button {
    flex: 0 0 auto;
    display: flex;
    
    .toolbar {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}