.repair-times {
    .repair-times__selection {
        display: flex;
        position: relative;

        .associated-repair-times {
            flex: 1;
            padding-left: 1em;
        }

        .fancy-list {
            flex: 1;

            &__head {
                .fancy-list {
                    &__block {
                        &--attributes {
                            margin-right: 0;
                        }
                    }
                }
            }

            &__body {
                .fancy-list {
                    &__item {
                        flex-wrap: wrap;
                    }

                    &__block {
                        &--actions {
                            gap: 0.25rem;
                        }
                        
                        &--attributes {
                            flex: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 0.25em;
                            white-space: pre;

                            &:empty {
                                display: none;
                            }
                        }

                        &--included-time {
                            flex: 0 0 31rem;
                            text-align: right;
                            padding-right: 12rem;
                        }
                    }
                }
                .checkbox {
                    margin-left: 0.5em;
                }
            }

            &__block {
                &--notes {
                    flex: 0 0 1.5rem;

                    .tooltip__wrapper {
                        white-space: pre-wrap;
                        width: max-content;
                        max-width: 30em;
                    }
                }
            }
        }

        .multi-selection {
            position: absolute;
            top: -1.5em;
            right: 1.25em;
            z-index: 1;
            .card__inner {
                display: inline-flex;
                align-items: center;
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.54);
                padding: 0.5em 1em;
            }
            .text,
            .btn {
                margin: 0 1em;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
