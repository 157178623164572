.bdl-parts {
    &.part {
        flex: 0 0 auto;
        
        .article__numbers {
            .text {
                user-select: unset;
                cursor: unset;
            }
        }
        .article__cell--thumbnail {
            margin-bottom: -0.25em;
        }
    }
}