.models {
    margin-bottom: 1em;

    &__headline {
    }

    &__pre-text {
        margin-top: 0.5em;
    }

    &__item {
        flex-wrap: wrap;
    }

    .fancy-list {
        flex: 1;

        &__block {
            margin-right: 1%;
        }

        &__item {
            a {
                text-decoration: none;
            }

            figure {
                cursor: pointer;
                padding: 1em 0;
                height: 7em;

                img {
                    display: block;
                    margin: auto;
                    max-height: 4em;
                    max-width: 100%;
                }

                .error-image {
                    svg {
                        max-height: 2em;
                        max-width: 100%;
                    }
                }

                figcaption {
                    font-size: 0.9em;
                    line-height: 1.2;
                }
            }
        }

        &__body {
            .inputs {
                flex: 100%;

                .inner {
                    align-items: center;
                    display: flex;
                    border-top: solid 2px #efefef;
                    margin: 0.5rem -0.75rem -0.5rem;
                    padding: 0.5rem 0.75rem;

                    @media (max-width: 1280px) {
                        .tip-message {
                            display: none;
                        }
                    }

                    .vehicle-data-inputs {
                        display: flex;
                        flex: auto;

                        input {
                            @media (max-width: 1280px) {
                                &::placeholder {
                                    font-size: 0.8rem;
                                }
                            }
                        }

                        .plateId,
                        .initialRegistration,
                        .mileAge {
                            margin-right: 0.3em;
                        }

                        .vin {
                            width: 40%;
                            padding-right: 0.3em;
                        }
                    }
                }

                label {
                    margin-right: 0.5rem;
                    &:after {
                        content: ":";
                    }
                }

                button {
                    margin-left: auto;
                }
            }
        }
    }
    .fancy-list.commercial {
        .infos {
            width: 20%;
            
        }
        .enginecode {
            width: 10%;
        }

        .daterange {
            width: 10%;
        }
        .powerHp {
            width: 7%;
        }
        .powerKw {
            width: 7%;
        }
        .capacity {
            width: 7%;
        }
        .fuel {
            width: 7%;
        }

        .axisConfig {
            width: 7%;
        }

        .tonnage {
            width: 4%;
        }
    }
    .infos {
        width: 24%;

        .data-source-label {
            flex: 100%;
            margin-bottom: 0.5em;
        }

        .thumbs {
            margin-top: 0.5em;

            .image {
                width: auto;
                max-width: 5.5em;
            }

            .error-image > svg {
                max-width: 100%;
            }

            .image, .error-image > svg {
                height: 2em;
            }

            svg {
                width: auto;
            }
        }
    }

    .enginecode {
        width: 10%;
    }

    .daterange {
        width: 10%;
    }

    .power {
        width: 10%;
    }
    .powerHp {
        width: 10%;
    }
    .powerKw {
        width: 10%;
    }
    .capacity {
        width: 10%;
    }
    .cylinderCount {
        width: 9%;
    }

    .fuel,
    .reg-no {
        width: 10%;
    }

    .actions {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        margin-right: 0;
    }

    .is-filter-element {
        border-radius: 3px;
        padding: 0 2px;
        margin: 0 -2px;
        white-space: wrap;
    }
}
