.worktask-loader {
    opacity: 0;
    background-color: #f6f6f6;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0.5em;
    right: 0.5em;
    border-radius: 3px 3px 0 0;
    z-index: 10;
    transition: opacity ease 200ms;

    &.loading {
        opacity: 1;
    }
}



