.tk-parts {
    .filters {
        display: flex;

        &--clipped {
            flex-direction: column;

            .filter {
                width: 20em;
            }
        }

        .filter {
            display: flex;
            flex-direction: column;
            min-height: 2em;

            .filter__search-field,
            .selection-list {
                padding-left: 2em;
            }

            .selection-list,
            .fitting-position {
                padding-top: 0;
            }

            .selection-list__item__value {
                white-space: normal;
            }

            .loader {
                margin: -0.25em 0.25em -0.25em 0;
            }

            &.collapsible {
                &--disabled {
                    .collapsible__header {
                        color: #ccc;
                        cursor: default;
                        pointer-events: none;
                    }
                    .icon:not(.filter-clip-icon) {
                        fill: #ccc;
                    }
                    .filter-clip-icon {
                        cursor: pointer;
                        pointer-events: auto;
                    }
                }
            }

            .collapsible {
                &__header {
                    flex-shrink: 0;
                }

                &__content {
                    overflow-y: auto;
                    overflow-x: hidden;
                    -ms-scroll-chaining: none;
                    overscroll-behavior: contain;

                    .fitting-position-wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 8em;
                        width: 100%;
                    }
                }
            }

            &--extended-assortment {
                padding-left: 1.5em;
            }
            &--availability,
            &--extended-assortment {
                min-height: fit-content;

                .collapsible__header {
                    padding: 0.3rem 0.5rem;
                }

                .collapsible__caption {
                    margin-left: 0;
                }
            }

            &--fitting-position {
                &.collapsible--opened {
                    &:not(.collapsible--dropdown) {
                        min-height: fit-content;
                    }

                    .collapsible__content {
                        display: flex;
                    }
                }

                .fitting-position {
                    height: 8em;
                    margin: auto;
                    padding: 0.25em;
                }
            }

            &--construction-year {
                &.collapsible--opened{
                    min-height: 5.1em;
                }

                .selection-list {
                    padding-bottom: 0;
                }

                &__button {
                    width: 100%;

                    .btn__content {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.filter--construction-year__item {
    text-align: center;
}

@import "./components/filter-modal";
