.vehicle {
    margin-bottom: 2rem;
    margin-right: 2rem;
    flex: 1;

    &__title {
        text-transform: uppercase;
        font-size: 1em;
        color: #727272;
    }

    &__thumbnail {
        position: relative;
        margin: 1.25em 1.563em 1.25em 0;

        &__manufacturer {
            position: absolute;
            left: -2.5em;
            top: 0;
            max-height: 4.688em;
            z-index: 0;
        }

        &__model {
            position: relative;
            z-index: 1;
            top: 0.625em;
            left: 1.563em;
        }
    }

    &__model {
        font-size: 1.125em;
        color: #494949;
        opacity: .87;
        margin-bottom: 1.25em;
    }

    .text {
        font-size: .8em;
        color: #202020;
        opacity: .54;
    }
}
