.worktask-change {
    .text-wrapper--with-icon {
        display: flex;
        align-items: center;

        .text {
            padding-left: .5em;
        }
    }

    &__conflicts {
        padding: 0 0 2*.5em 0;

    }

    &__conflict-notes {
        padding-top: .5em;
    }

    &__options {
        padding: 0 0 2*.5em 0;
    }

    &__information {
        padding: 2*.5em 0;

        .worktask-change__head {
            padding-bottom: .5em;
        }

        >.text-wrapper + .text-wrapper{
            padding-top: .3em;
        }

        .intro {
            display: flex;
            margin: 0.5em 0;
            .icon {
                margin-right: 0.3em;
            }
        }
    }

    &__option {
        &--row {
            flex: 1;
            display: flex;
            padding-bottom: .5em;

            &:last-child {
                padding-bottom: 0;
            }
        }
        &--col {
            padding-right: .5em;

            &:last-child {
                padding-right: 0;
            }

            > div {
                padding-bottom: .5em;
                &:last-child {
                    padding-bottom: 0;
                }
            }

            > .checkbox + .checkbox {
                padding-top: .25em
            }
        }
        &--stretch {
            flex: 1;
        }
    }

    &__head {
        padding-bottom: 1em;
    }

    .switch {
        display: block;
        .text {
            font-size: 0.875rem;
            line-height: 1.2;
        }
    }
    .section {
        margin-bottom: 1em;
        margin-left: 3em;
    }
}
