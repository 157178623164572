.tk-vehicle__vin-request {
    margin: auto;
    width: 475px;
    max-width: 100%;
    &__title {
        margin-bottom: 1.5em;
    }

    .panel__title {
        padding-left: 0;
    }

    .list {
        display: flex;
        flex-wrap: wrap;
    }

    .list-item {
        padding: 0.2em;
        flex: 0 0 50%;
        &.model {
            flex: 0 0 100%;
        }
        &.manufacturer {
            flex: 0 0 100%;
        }
    }
    
    .request-text-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .icon--xl {
            height: 4rem;
            width: 15rem;
        }

        text {
            width: 85%;
            margin-bottom: 1.5em;
            margin-top: 1rem;
        }
    }

    .card__inner {
        padding: .5rem;

        .note {
            margin: .5rem;
        }
    }

    .input__field.input__field--multiline {
        height: 4em;
    }

    .bottom-wrapper {
        display: flex;
    }
}

.request-btn-wrapper {
    margin: 0.5em;
}