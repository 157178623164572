.bundle-misc {
    &.external-catalogs-basket {
        display: flex;
        flex-wrap: wrap;
        align-self: flex-start;
        align-items: center;
        .item {
            height: 7em;
            width: 7em;
        }
    }

    &.external-catalogs:not(.dropdown) {
        display: flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
        align-self: flex-start;

        &.group {
            .item-list {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin: 0 -0.5em;

                .item {
                    flex: 0 0 33.333%;
                    padding: 0.25em 0.5em;
                    margin-bottom: 0.5em;
                    border-radius: 3px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    .text { 
                        margin-left: 0.5rem
                    }

                    &:hover {
                        background-color: rgba(0,0,0,0.1);
                    }
                }
                &.cols-1 .item {
                    flex: 0 0 100%;
                }
                &.cols-4 .item {
                    flex: 0 0 25%;
                }

                &.layout-vertical {
                    .item {
                        flex-wrap: wrap;
                        text-align: center;
                        display: initial;

                        img { margin : 0 auto; }
                        .text { display: block; }
                    }
                }
            }
        }
    }
}
