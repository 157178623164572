.tk-feedback {
    &.toolbar-form {
        .tooltip__content {
            padding: 3em 4em;
            width: 580px;
            position: relative;

            .content {

                >* {
                    display: block;
                }

                .headline {
                    margin-bottom: 1em;
                    text-align: center;
                    white-space: normal;
                }

                .actions {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                }

                .description {
                    margin-bottom: 1em;
                    text-align: center;
                }

                .inner {
                    display: flex;

                }

                .ratings {
                    text-align: center;

                    .rating {
                        overflow: visible;
                        opacity: .54;
                        height: 4em;
                        width: 3.75em;
                        transition: .2s;
                        cursor: pointer;

                        &.selected {
                            opacity: .87;
                            transform: scale(1.2);
                        }

                        &:hover {
                            opacity: .87;
                            transform: scale(1.2);
                        }
                    }
                }

                .feedback-box {
                    position: relative;
                    margin-top: 5px;

                    .input--textfield {
                        margin-bottom: 6px;
                    }
                    textarea.input__field  {
                        height: 6 * 1.3em;
                    }
                    .btn--success {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }

                .buttonBox{
                    padding-top: 10px;
                    justify-content: space-between;
                    display: flex;
                    flex-direction: row;
                }
            }
        }

        &.showThanks {
            .tooltip__content {
                padding: 2em;
                height: 15em;
                width: 15em;

                .thanks {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 100%;
                    justify-content: center;

                    .closer {
                        position: absolute;
                        right: 2em;
                        top: 2em;
                    }

                    .thanks-icon {
                        height: 5em;
                        width: 5em;
                        margin-bottom: 1em;
                    }
                }
            }
        }

        .animation {
            animation-name: myAnimation;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-delay: 0s;
        }
    }
}

@keyframes myAnimation {
    0% {
        opacity: .38;
        transform: scale(1)
    }
    50% {
        opacity: 1;
        transform: scale(1.2)
    }
    100% {
        opacity: .38;
        transform: scale(1)
    }
}
