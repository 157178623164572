@import "./components/adding-tools/index";
@import "./components/cost-estimation/index";
@import "./components/_shared/order-options/index";
@import "./components/show-all-orders-button/index";
@import "./micros/add-to-basket/index";

.basket-parts {
    padding: .5em;
    > .layout {
        > .layout__slot-0 {
            flex-direction: row;
            flex: 0 0 auto;
            align-items: center;
            padding-bottom: .3em;            
        }
    }
}

.basket {   
    .layout__slot {
        flex-direction: column;
    }
}

.tk-crm.customer-overview {
    flex: 1;
}