.tk-vehicle {
    &.search-tree-widget {

        .suggest--new {
            .suggest__box {
                padding-top: 3em !important;
            }

            .input {
                padding-left: calc(5px - 0.05em);
            }
        }


        .tooltip__wrapper {
            .text {
                font-size: 0.875em;
                line-height: 1.2;
            }
            min-width: 16em;
        }

        .text.label {
            &:after {
                content: ":";
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            .text {
                display: block;
                align-self: flex-start;
            }
            .btn {
                margin-top: 0.5em;
            }
        }

        .selection-container {
            width: 100%;
            margin: 0 auto;

            .text.label {
                display: block;
                margin-bottom: 1em;
            }

        }

        .popover {
            &__inner {
                padding: 0.2em;
            }
        }
        .dropdown {
            &__item {
                padding: 0.2em 0.3em;
            }
        }

        .btn.vehiclesearch {
            font-size: 0.875em;
            line-height: 1;
            .icon {
                height: 1.5em;
                width: 1.5em;
            }
            .btn__content {
                text-align: left;
            }
            small {
                font-size: 0.625rem;
                line-height: 1;
            }
        }

        .row {
            display: flex;
            margin: 1em 0;

            .number {
                width: 2rem;
                align-items: center;
                justify-content: center;
                display: flex;
                border: 1px solid #ccc;
                margin-right: 1rem;
                font-size: 1.4em;
            }
            .dropdown {
                flex: 1;
            }
        }

        .line {
            display: flex;
            width: 80%;
            margin: 1em auto;
            &:before, &:after {
                content: "";
                flex: 1;
                border-top: 2px solid #666;
                margin-top: 0.5em;
            }
            .text {
                margin: 0 0.5em;
            }
        }

        .dropdown {
            margin: 0;

            .btn {
                &__content {
                    .description {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 15rem;
                    }
                    .date-range {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 8.25rem;
                    }
                }
            }

            &__box {
                min-width: 100%;
                width: auto;
            }
            .item {
                display: flex;
                align-items: center;
                white-space: nowrap;
                > * {
                    margin-right: 0.5em;
                }
            }
            .thumb {
                flex: 0 0 3.25em;
                margin-right: 0.25em;
            }
            .description {
                flex: 1;
                width: 22em;
            }
            .image {
                height: 1.2em;
                width: 3.25em;
                display: block;
                object-fit: contain;
            }
            .error-image {
                svg {
                    height: 1.2em;
                    width: 1.2em;
                }
            }
        }
    }
}
