.tk-wheels {
    .wheels-list {
        flex: 1;
        display: flex;
        flex-direction: column;

        .content_wheels-information {
            margin-left: $spacing-XL;
            display: flex;

            .display-button-config {
                margin-left: $spacing-M;

                img {
                    height: 1em;
                    width: auto;
                    margin-right: $spacing-M;
                }
            }
        }

        .header_wheels_list {
            position: relative;
            display: flex;
            overflow: hidden;
            padding-bottom: $spacing-M;

            .rim-settings {
                padding-left: $spacing-XXL;
                flex: 0 0 40%;

                .panel__content {
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    .rim-settings__header {
                        justify-content: space-between;
                        display: flex;
                        align-items: center;
                    }

                    .rim-settings__content {
                        display: flex;
                        height: 100%;

                        .rim-settings__sliders {
                            flex: 0 30%;
                            margin-right: $spacing-M;

                            .slider {
                                margin: 0;

                                .range-slider {
                                    margin-top: 0.5em;
                                }

                                &:last-of-type {
                                    margin-top: $spacing-M;
                                }
                            }

                            .buttons {
                                display: flex;
                                justify-content: space-around;
                            }
                        }

                        .rim-settings__colors {
                            flex: 1;

                            .scrollbar__view {
                                display: flex;
                                flex-wrap: wrap;

                                .card {
                                    padding: 0 $spacing-S $spacing-S $spacing-S;
                                    width: 25%;

                                    .color_selection {
                                        height: 4em;
                                    }

                                    .color_text {
                                        padding: 0.5em;
                                        overflow: hidden;
                                        text-align: center;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .rim_info {
                margin-bottom: $spacing-M;
                flex: 1;
                padding-left: $spacing-XXL;
                height: 285px;
                overflow: hidden;
                margin-right: $spacing-M;

                .panel__content {
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    .rim-info__header {
                        justify-content: space-between;
                        display: flex;
                        align-items: center;
                        margin-bottom: $spacing-M;
                    }

                    .rim-info__content {
                        flex: 1;

                        .vehicle__informations {
                            display: flex;
                        }

                        .vehicle__informations,
                        .drive-right-tyres,
                        .sensors-content {
                            height: 100%;

                            .image,
                            .no-image {
                                width: 3em;
                                height: 3em;

                                >svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .fancy-list {
                                flex: 1;

                                &__item,
                                &__head {
                                    margin-right: 1em;
                                }

                                &__block {
                                    flex: 1;
                                }

                                &__block.size {
                                    flex: 2;
                                }

                                &__block.season {
                                    flex: 0;
                                    min-width: 4rem;
                                }
                            }
                        }

                        .drive-right-tyres {
                            height: 95%;
                            .fancy-list {
                                &__item {
                                    .search-icon.disabled {
                                        opacity: .2;
                                    }

                                    .search-icon:first-child {
                                        margin-right: .25em;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .action-icons {
                display: inline-flex;
                flex-direction: column;
                position: absolute;
                top: 40%;
                z-index: 1;
                margin-left: $spacing-XL;

                .btn {
                    margin-left: 0;
                    margin-top: $spacing-S;
                }
            }
        }

        .wheels-list_content {
            flex: 1;
            display: flex;

            .article-list__status {
                margin-top: 0;
            }

            .wheels-list_filters {
                flex: 0 0 20%;

                .diameter-filters {
                    margin-bottom: $spacing-M;

                    .toolbar__inner,
                    .dropdown {
                        flex: 1;
                    }
                }

                .collapsible {
                    padding: 0 $spacing-M;

                    &__content {
                        margin-left: $spacing-M;
                    }
                }

                &.loading_cover {
                    opacity: 0.6;
                    pointer-events: none;
                }
            }
        }
    }
}