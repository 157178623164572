&__availability {
    .tooltip__content {
        white-space: nowrap;
    }

    &__wrapper {
        position: relative;
        display: flex;
        align-items: center;
        > .popover {
            left: 50%;
            transform: translateX(-50%);
            z-index: 1000
        }
    }
}