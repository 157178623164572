$max-childs: 4;

.tk-vehicle {
    .selections {
        display: flex;
        position: relative;

        &__card {
            position: relative;
            flex: 1;
            padding: 1em 1.5em;
            border-radius: 3px;
            margin-left: -1px;
            display: flex;
            align-items: center;
            width: 25%;

            &:first-child {
                margin-left: initial;
            }

            @for $i from 1 through $max-childs {
                &:nth-child(#{$i}) {
                    @if $i == 1 {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    @else if $i == $max-childs {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                    @else {
                        border-radius: 0;
                    }
                    z-index: $max-childs + 1 - $i;
                }
            }
        }
        &__content {
            display: flex;
            align-items: center;
            height: 2rem;
            width: 100%;

            .inputgroup {
                width: 100%;
            }

            .input {
                width: 100%;

                &--s {
                    height: 2.4em;
                }

                &__inner {
                    background: #fafafa;
                }
            }

            &__search-reset {
                border-radius: 3px;
            }
        }
        &__title {
            font-weight: 600;
        }
        &__image {
            max-height: 2em;
            margin-right: 1em;
        }
        .error-image {
            svg {
                max-height: 2em;
                max-width: 2em;
            }
        }
        &__headline {
            margin-right: 0.5rem;
            font-size: 1.2em;
            line-height: 1.2;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .icon--check {
            position: absolute;
            right: 1em;
            top: 1em;
            opacity: 0.1;
            &.icon--primary {
                opacity: initial;
            }
        }
    }
}