.tk-vehicle.vehicle-details {
    .switch-container {
        display: flex;
        justify-content: flex-end;
        padding-top: 0.5em;

        .switch--disabled .switch__content, .switch:disabled .switch__content {
            opacity: .54;
        }
    }

    .switch--no-status {
        .switch__content:before {
            display: none;
        }
        .switch__icon,
        .switch__unchecked {
            visibility: hidden;
        }
    }

    .flex-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.5rem;
    }

    .personal {
        .header {
            display: flex;

            .headline {
                flex: 1;
            }
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .data {
            display: flex;
            flex-wrap: wrap;
            padding: 1em 0;
            max-width: 60rem;
        }

        .column-right {
            margin-left: 2em;
        }

        .servicebook {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1.5em;

            .headline {
                margin-right: 0.5em;
            }
        }

        .col {
            flex: 0 0 33.3333%;
            padding: 0.5em;

            .plate-id {
                width: 100%;
            }

            .react-datepicker-wrapper,
            .react-datepicker__input-container {
                display: block;
            }
        }

        .suggest__box {
            min-width: calc(100% - 0.5em);
        }
    }

    .selections {
        margin: 0 -0.5rem;
        padding: unset;
        .col {
            flex: 0 0 33.3333%;
            padding: 0.5rem;
            display: flex;
        }
    }

    .images {
        flex: 0 0 33.3333%;
        padding: 0 0.5rem;
        //this block of code is to fix the height to the box next to it
        .panel {
            height: 14.125em;
            &__content {
                height: 100%;
            }
        //end of block
        }
        .manufacturer {
            display: none;
        }
        .model {
            width: auto;
            height: 100%;
            margin: 0 auto;
            display: block;
            svg {
                height: 100%;
                opacity: .3;
                width: 100%;
            }
        }
    }

    .tecdoc {
        flex: 1;
        padding: 0 0.5rem;

        .regno-wrapper {
            position: relative;

            &:before {
                content: "...";
                float: right;
                padding-left: 0.25em;
            }

            &__preview {
                max-height: 1.2em;
                overflow-y: hidden;
            }

            &__popover {
                display: none;
                position: absolute;
                top: -0.5em;
                left: -0.5em;
                width: calc(100% + 1em);
                max-height: 10em;
                overflow-y: auto;
                padding: 0.5em;
                background-color: #fafafa;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }

            &:hover {
                .regno-wrapper__popover {
                    display: block;
                }
            }
        }
    }

    &__reg-no {
        .data-table {
            &__key,
            &__value {
                width: 50%;
                //opacity: $opacity-primary;
            }
        }
    }

    &__details {
        .panel__content {
            overflow: visible;
        }
    }
}
