@import "./components/widget/index";
@import "./components/widget-start/index";
@import "./components/navigation/index";
@import "./components/transitions/index";
@import "./components/customer-details/index";
@import "./components/customer-vehicles/index";
@import "./micros/vehicle-info/index";
@import "./micros/customer-info/index";

.module-crm {
    > .layout {
        flex-direction: column;
        > .layout__slot-0 {
            flex: 0 0 auto;
        }
    }
    .loader-spinner {
        flex: 0 0 auto;
        margin: 0.5em;
    }
}

.module-customer-details,
.module-customer-list {
    .layout__slot-0 {
        flex-direction: column;
        align-items: stretch;
    }
}

.module-customer-details {
    overflow-y: auto;
}