.vehicle-widget {
    @import "./components/special-license-plates/index";

    &.is-disabled {
        opacity: .54;
        pointer-events: none;
    }

    .special-license-plates {
        margin-bottom: 0.5rem;
    }

    &__special-license-plates-micro {
        width: 100%;

        > .inputgroup {
            flex: 1;

            .suggest__box {
                font-size: 1rem;
            }
        }
    }

    &__hint {
        display: flex;
        align-items: center;
        padding-top: 1em;
        .icon {
            margin-right: 0.5em;
        }
    }

    .widget__content {
        transition: all 0.2s ease;

        .category-selection {
            .subtitle {
                margin-bottom: 5px;
            }
            .tab {
                margin-right: 3px;
                border-radius: 3px;

                &.is-selected {
                    transform: translateY(3px);
                }
            }
        }
    }

    .widget__footer {
        .fav-manufactureres-subtitle {
            padding-bottom: 1em;
        }

        .manufacturers {
            display: flex;
            flex: auto;

            .card {
                margin: 0 10px 0 0;
                padding: 0;
                width: calc(16.66% - 8.33px);
                cursor: pointer;

                @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                    flex: 1 1 auto;
                }

                &__inner {
                    flex: 1 1 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: .5em;
                    font-size: 0.75rem;
                    min-height: 2.75rem;

                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .image {
                        max-height: 3rem;
                    }

                    .error-image > svg {
                        height: 2.5em;
                    }

                    .image, .error-image > svg {
                        display: block;
                        width: auto;
                        max-width: 100%;
                    }
                }
            }

            .btn {
                display: inline-flex;
                width: calc(16.66% - 8.33px);
                min-width: fit-content;
                justify-content: center;

                &::before {
                    content:'';
                    display: inline-block;
                    text-align: center;
                    vertical-align: middle;
                    height: 100%;
                }

                .btn__content {
                    display: inline-block;
                    align-self: center;
                    vertical-align: middle;


                    @media (max-width: 1680px) {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    .card__curtain {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(#202020, 0.54);
        z-index: 1;
    }

    .card__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;

        .loader {
            margin-left: -50%;
            margin-top: -50%;
            font-size: 2em;
        }
    }

    .card__intro {
        display: flex;
        margin: 1em 0 1.5em;
        justify-content: center;

        &__text {
            text-align: right;
            margin-right: 1em;
        }
    }

    .btn:not(.btn--icon-only) {
        display: inline-flex;
    }

    .engine-codes {
        text-overflow: ellipsis;
    }

    .content__head {
        display: flex;
        align-items: stretch;

        .description {
            flex: 0 0 22em;
        }

        .subtitle {
            opacity: .54;
        }

        .detail .subtitle {
            opacity: 1;
            text-overflow: ellipsis;
        }

        .vehicle-image {
            flex: 1;
            margin: .5em .25em;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .error-image {
                display: block;
                width: 100%;
                height: 100%;
            }

            .image,
            .error-image {
                max-width: 100%;
                max-height: 5em;

                svg {
                    width: 100%;
                    height: 100%;
                    opacity: .3;
                }
            }
        }
    }

    .tooltip__wrapper {
        min-width: 20em;
    }

    .details {
        display: flex;
        margin: 0.25em 0;
    }

    .detail {
        flex: 0 0 6em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-left: solid 1px #c3c3c3;
        padding: .125em .5em;
        overflow: hidden;
        cursor: default;

        &.is-clickable {
            cursor: pointer;

            .input__field {
                cursor: pointer
            }
        }

        .input__field {
            cursor: default
        }

        .subtitle {
            opacity: .54;
            text-overflow: ellipsis;
            margin-bottom: 0.5em;
        }

        .value {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.unfilled {
                opacity: .54;
            }

            &.plate-id {
                &.unfilled {
                    opacity: .87;
                }
            }
        }

        &--fill {
            flex-basis: 0;
            flex-grow: 1;
        }

        &--large {
            flex-basis: 9.1em;
        }

        &--small {
            flex-basis: 4em;
        }

        &--license-plate {
            justify-content: flex-end;
            padding-left: 0;
            border: none;
        }

        &--vin {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--engine-codes {
            overflow: visible;
            flex: 0 0 auto;

            .btn__content {
                opacity: .875;
            }
        }

        &--btns {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .btn {
                padding: 0;
            }

            .icon {
                width: 1.5em;
                height: 1.5em;
            }
        }

        &:last-child {
            padding-right: 0;
        }

        .text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .editor {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 1em;
        z-index: 4;

        .vehicle-data-progress {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
    }

    &.has-vehicle {
        .widget__footer {
            display: flex;
            align-items: flex-end;
            // justify-content: flex-end;
        }
    }

    .vehicle-data-progress {
        font-size: 0.5em;
        margin-left: auto;
        padding: 0;
        padding-top: 0.1em; // Fix cutted off edge on transform transition
        z-index: 3;
        transform-origin: bottom right;
        transition: transform 0.25s ease-in-out 0.4s;
        will-change: transform; // Fix some flickering issues during transition

        &__circle {
            position: relative;
            z-index: 1;

            &:not(:hover) + .vehicle-data-progress__checklist {
                pointer-events: none; // Checklist is not interactable when not shown (it would overlap other elements otherwise)
            }
        }

        &__checklist {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(4em, 1em);
            opacity: 0;
            transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s ease-in-out;
            will-change: transform, opacity; // Fix some flickering issues during transition

            &:hover {
                pointer-events: unset !important; // When checklist is shown it should not minimize on hover
            }
        }

        &:hover {
            transform: scale(2);

            .vehicle-data-progress__checklist {
                transform: translate(5em, 1em);
                opacity: 1;
                transition-delay: 0.8s;
            }
        }
    }

    .btn {
        &--top-right {
            position: absolute;
            right: 1rem;
            top: 1rem;
            padding: 0;

            .icon {
                width: 1.5em;
                height: 1.5em;
            }
        }
    }
}
