.tk-wheels {
    .loading-screen {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .widget__cover .icon {
            height: 5em;
            width: 5em;
        }
        .headline {
            margin-bottom: $spacing-M;
        }
        .loader-spinner {
            display: block;
            visibility: hidden;
            &--visible {
                visibility: visible;
            }
        }
    }
}
