@import "./components/concept-logo/index";
@import "./components/filter/components/select-articles/index";
@import "./components/next-tour/index";
@import "./components/returns/index";

.tk-cis {
    > .layout {
        > .layout__slot-0 {
            flex: 0 0 auto;
        }

        > .layout__slot-1 {
            align-items: initial;
            flex: 1;
        }
    }
}

.cis-navigation-wrapper {
    > .layout {
        > .layout__slot-0 {
            flex: 1 1 auto;
        }
        > .layout__slot-1 {
            flex: 0 2 auto;
        }
    }
}

.cis-navigation-wrapper.toolbar {
    width: 100%;
    flex: none;
    
    .layout {
        display: block;
    }
}