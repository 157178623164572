
$global-radius: 3px;
$color-light: #ffffff;

.tk-parts {
    &.category-selection {

        .tab-control {
            margin: 0 -3px 3px 0;
            overflow: hidden;
        }

        .tab {
            margin-right: 3px;
            border-radius: $global-radius;
            overflow: hidden;
            position: relative;

            &.is-selected {
                transform: translateY(3px);
            }

            >&__content {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .icon {
                margin-right: 0.25em;
                display: inline;
                vertical-align: middle;
            }
        }
    }
}
