.tk-erp {
    .erp-info {
        @import "../../../_shared/availability-wrapper";
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        padding: .5em 0;

        &__prices {
            display: flex;

            border-right: 1px solid gray;
            border-left: 1px solid grey;
            margin: 0 0.5rem;
            padding: 0 0.5rem;

            &--two-column {
            }

            &--disabled {
                border: none;
            }

            &:last-child {
                margin-right: 0;
            }

            .erp-info__staggered {
                padding-right: 1em;

                height: 100%;
                display: flex;
                align-items: center;

                &__disabled {
                    display: none;
                }

                &__clickable {
                    cursor: pointer;
                }

                .btn {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        &__availability {
            display: flex;
            align-items: center;

            &__disabled {
                visibility: hidden;
            }

            .erp-info__alternatives {
                border-right: 1px solid gray;
                padding-right: 0.5em;
                margin-right: 0.5em;
                height: 100%;

                display: flex;
                align-items: center;

                &__disabled {
                    visibility: hidden;
                }

                &__clickable {
                    cursor: pointer;
                }

                .btn {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        @import "../../../_shared/prices";

        .loader {
            margin: 0 0.25em;
        }
    }
}
