.form-confirmation {
    .item {
        margin-bottom: 1em;

        &:last-child {
            margin-bottom: 0;
        }

        .checkbox {
            .checkbox__label {
                margin-left: 1em;
            }
        }
    }

    .PDF-IFrame {
        display: block;
        //4em depends on padding and 2px on border of Modal Dialog
        width: calc(100% + 4em - 2px);
        height: 100%;
        margin: 0em -2em -1em;
    }
}
