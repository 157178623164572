.tk-parts {
    &.list-options {
        .tooltip__content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .list-options__switch-wrapper:not(:last-child) {
            margin-bottom: 0.25em;
        }

        &__tooltip .tooltip__wrapper {
            max-width: 35em;
            width: max-content;
            white-space: pre-wrap;
        }
    }
}
