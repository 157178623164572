.worktask-create-button {
    height: 3em;
    width: 3em;
    margin: 0 .2em;
    display: flex;
    align-items: center;
    justify-content: center;
    &.btn--icon-only {
        margin-right: 0.2em;
    }
    .icon {
        width: 1.25em;
        height: 1.25em;
    }
}
