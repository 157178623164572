.tk-worktask {
    &.recent-list {

        .headline {
            display: flex;
            flex: 0 0 auto;
            .icon {
                margin-right: 0.5em;
            }
            margin-bottom: 0.25em;
            align-items: center;
        }
        .loader {
            height: 1em;
            width: 1em;
            margin-left: 1rem;
        }
        .list {
            flex: 1;
            position: relative;
            overflow: auto;
            padding: unset;
        }
        a {
            text-decoration: none;
        }
        .date-plate {
            flex: 0 0 7em;
            margin: 0;
            padding-right: 1em;
        }
        .vehicle-image {
            flex: 0 0 10em;
            margin: 0;
            padding-right: 1em;
        }
        .customer-vehicle {
            display: block;
            flex: 1;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            // .inner {
            //     display: none;
            // }
        }
        .total-numbers {
            flex: 0 0 3.5em;
            margin: 0;
        }
        .total {
            display: flex;
            flex-direction: row;
            align-items: center;
            .badge {
                position: inherit;
            }
            .icon {
                margin-right: 0.1em;
            }
            .loader {
                margin: 0;
            }
        }
        .time {
            font-family: "Roboto Condensed";
            min-height: 1.3em;
            font-size: 0.875em;
        }
        .plate {
            min-height: 1.3em;
            flex: 0 0 auto;
        }
        .customer,
        .vehicle {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-height: 1.3em;
        }
        .fancy-list {
            position: relative;
            -webkit-mask-image: linear-gradient(to bottom, #000 50%, rgba(0,0,0,0));
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            transition: all 500ms ease-in-out;
            &:hover {
                -webkit-mask-size: 100% 200%;
            }
            &__item {
                padding: 0.1875em 0.5em;
                margin: .25em .75em .5em 0;
                opacity: .87;
                transition: opacity 200ms ease;

                &:hover {
                    opacity: 1;
                }
            }
        }
        .error-image {
            display: block;
            visibility: hidden;
            height: 2.2em;
            width: 2.2em;
        }
        .vehicle-thumbnail {
            height: 2.2em;
        }
    }
}
