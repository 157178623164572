.tk-vehicle {
    .mainmodelseries {
        flex: initial;

        &__header {
            display: flex;
        }

        &__headline {
            margin: 0 1rem 0.5rem 0.5rem;
        }

        &__list {
            margin: 0 -.5em;
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            cursor: pointer;

            a {
                text-decoration: none;
                padding: 1em;
                display: inline-block;
            }
        }

        .loader {
            width: 3rem;
            height: 3rem;
            margin-left: calc(50% - 1.5rem);
        }
    }
}
