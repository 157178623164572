.bundle-misc {
    &.widget-cover-only {
        &.is-disabled {
            opacity: 0.64;
            pointer-events: none;
        }
        .loader {
            position: absolute;
            right: 0.5em;
        }
        .image.widget__cover-icon {
            width: auto;
        }
    }
}
