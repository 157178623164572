@import "./components/list/index";
@import "./components/list/components/comparison/index";
@import "./components/list/components/oe/oe-positions/index";
@import "./components/list-options/index";
@import "./components/details/index";
@import "./components/details-head/index";
@import "./components/details-navigation/index";
@import "./components/details-references/index";
@import "./components/filters/index";
@import "./components/widget/index";
@import "./components/navigation/index";
@import "./components/search/index";
@import "./components/_shared/category-selection/index";
@import "./components/_shared/oe/oe-article/index";
@import "./components/_shared/article-item/index";
@import "./components/part/index";
@import "./components/search-widget/index";
@import "./components/simple-search-widget/index";
@import "./components/uni-search-widget/index";
@import "./components/direct-search-widget/index";
@import "./components/details-head/ie.scss";
@import "./components/history/index";
@import "./components/details-frame/index";
@import "./components/listV2/index";


.tk-parts {
    &.toolbar__group {
        align-self: flex-end;
    }

    .toolbar__title {
        margin-bottom: 0.5em;
    }
}
.module--parts-container {
    overflow: hidden;
}

.module--parts {
    height: 100%;
    position: relative;

    >.layout {
        >.layout__slot-1 {
            flex: 1;
            overflow: hidden;

            .article-details__details {
                height: 100%;
                min-height: 40em;

                >.layout.layout--two-slot {
                    height: initial;
                    flex: 1 1 auto;

                    >.layout__slot {
                        &.layout__slot-1 {
                            flex: 1;
                            min-height: 24.6em;

                            .layout__switch {
                                height: initial;
                                flex: 1 1 auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

.module--parts-list {
    >.layout {
        >.layout__slot-0 {
            >.tk-parts {
                flex: 1;
                display: flex;

                .list {
                    flex: 1;
                }

                #article-list-filters {
                    .tk-parts {
                        height: 100%;

                        .filters {
                            flex: 1;
                            height: inherit;
                        }
                    }
                }
            }
        }

        >.layout__slot-1 {
            flex: 0 0 auto;
        }
    }
}

.layout--part-alternatives {
    >.layout {
        >.layout__slot-1 {
            flex: 1;
        }
    }
}

.module--part-alternatives {
    .tk-parts {
        .list {
            .article-group__description {
                background-color: #FFF; // should be the same as the modal background
            }
        }
    }
}

.article-details__content {
    .layout--two-slot,
    .layout--one-slot {
        height: inherit;
    }

    &.article-list__partslist {
        .layout--two-slot,
        .layout--one-slot {
            .layout__slot {
                height: 100%;
                .panel {
                    margin: 0;
                    padding-right: 0.2em;

                    .panel__content {
                        height: calc(100% - 3.5em);
                    }
                }
            }
        }
    }
}

.article-details__overview {
    .article-details__downloads {
        display: flex;
        flex-wrap: wrap;
    }
}

.image-viewer__image {
    transition: none;
    max-height: 75vh;
    max-width: 75vw;
}

.parts__search-filters {
    .selection-list,
    &__checkbox-group {
        padding: .3em;
    }

    &__checkbox-group {
        padding-top: .1em;
    }
}
