.tk-cis.concept-logo {
    height: 100%;
    display: flex;
    align-items: center;

    .tooltip {
        line-height: 0;
        margin-left: 0.5em;

        &__content {
            white-space: nowrap;
        }
    }

    .concept-logo_image {
        align-self: center;
        max-height: 2.5em;

        &.error-image {
            display: none;
        }
    }
}
