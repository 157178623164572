.worktask-info {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0 0.625em 0 0.625em;

    .toolbar {
        padding: 1em;
        display: flex;
    }

    .text {
        padding-right: .4em;
    }
}