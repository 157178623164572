.tk-settings.user-settings {
    padding: 0.7rem 1rem 1rem 2rem;
    display: flex;
    flex-direction: column;
    flex: 1;

    .btn {
        margin: 0 .2rem .2rem 0;

        &:last-child {
            margin-right: 0;
        }
    }

    #user-settings__concept-page {
        .success {
            display: flex;
            .icon {
                margin-right: 0.5em;
            }
        }
    }
    .user-settings {
        &__headline {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .btn {
                margin: 0;
            }
        }

        &__content {
            display: flex;
            flex: 1;

            margin-top: 1rem;
        }

        &__infos {
            flex: 3;

            .user-settings__area {
                margin: 0;
            }
        }

        &__settings {
            flex: 7;
            display: flex;
            flex-direction: column;

            padding: 0 1rem;

            .tab-control {
                margin-bottom: 1rem;
                flex-wrap: wrap;
            }

            .switch > .text {
                font-size: 1rem;
            }
            .shortcut {
                border-top:1px solid #d3d3d3;
                padding:0.5em;
            }
            .content{
                margin-top: 1em;
            }

        }

        &__area {
            .panel__title {
                border: 0;
                padding: 0 0 0.625em;

                .panel__text {
                    display: flex;
                    align-items: center;
                }

                .icon {
                    height: 1.5rem;
                    width: 1.5rem;

                    margin-right: 0.5rem;
                }
            }

            .panel__content {

                > .text {
                    margin-bottom: 1rem;
                }

                .error-image svg {
                    max-height: 6rem;
                }
            }

            &__row {
                width: 70%;
                display: flex;
                margin-bottom: 1rem;

                > * {
                    flex: 1;
                    margin-right: 0.5rem;
                }
                
                .input--dropdown {
                    .input__inner {
                        height: 100%;
                        padding: 0.4em;
                        .input__field {
                            height: fit-content;
                        }
                        label.input__label {
                            transform: translateY(-.65rem);
                        }
                        div.input__field {
                            transform: translateY(0.45rem);
                        }
                    }
                }
            }

            &__licensor {
                .licensor__information {
                    margin-bottom: 1rem;
                }
            }
        }

        &__provider {
            height: 2.5rem;
            padding: 0.5rem;
            margin-right: 0.5rem;

            &:hover {
                cursor: pointer;
                opacity: 0.6;
            }
        }

        &__location {          
            &__address-field {
                z-index: 4;
            }

            &__suggest {
                width: calc(75% - 1.5em);
                position: absolute;

                .fancy-list__block {
                    flex: 1;
                }
            }
        }

        &__statistic {
            &__details-button {
                margin-bottom: 1rem;
            }
        }

        &__sub-user-management {
            .real-table {
                &__cell {
                    &.edit, &.delete {
                        width: 1px;
                    }
                }
            }
        }

        &__repair-times {
            &__unit-headline {
                margin-top: 0.8rem;
                margin-bottom: 0.5rem;
            }

            &__division-radios {
                margin-top: 0.6rem;

                .radio {
                    margin: 0.3rem 0 0 1.8rem;

                    &__label {
                        margin-left: 0.5rem;
                    }
                }
            }

            &__default-rt-selection {
                display: flex;
                align-items: center;

                >.text {
                    margin-right: 0.5rem;
                    line-height: 1;
                }
            }

            .switch {
                opacity: 1;
            }
        }

        &__area__module {
            .collapsible__caption {
                display: none;
            }
        }
    }
}

.user-settings__area__module__confirmation__message {
    .message-toast__message
    {
        font-weight: bold;
    }
}

.user-settings__sub-user-editor {
    width: 100%;

    &__actions {
        margin: 1rem 0.5rem;
    }

    &__form {
        > .input, > .username-input-wrapper {
            margin-top: 0.5rem;
            margin-left: 0.5rem;
        }

        > .username-input-wrapper {
            display: flex;
            align-items: stretch;

            > .username-prefix {
                display: flex;
                align-items: center;
                border-right: none;
                padding: 0 0.8rem 0 0.5rem;
                margin-right: -3px;
            }

            > .input {
                flex: 1;
            }
        }

        .switch {
            display: flex;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
            opacity: 0.8;

            & > .text {
                font-size: 1rem;
            }
        }

        &__hint {
            margin-left: 0.5rem;
            margin-top: 0.2rem;
        }
    }

    &__permissions {
        margin-top: 1rem;
    }
}

/*Special Styling for OneTrust external Script
https://jira.dvse.de/browse/NEXT-22301 */
body #ot-sdk-btn.ot-sdk-show-settings,
body #ot-sdk-btn.optanon-show-settings,
body #ot-sdk-btn.ot-sdk-show-settings:hover,
body #ot-sdk-btn.optanon-show-settings:hover {
    border: 1px solid #c3c3c3;
    color: #202020;
    line-height: 1.2;
    background-color: transparent;
    font-size: 1rem;
    padding: 0.4em;
}
