.article-details__frame {
    flex: 1;
    display: flex;
    flex-direction: column;

    .panel__content {
        flex: 1;
    }

    &__control {
        height: 100%;
        width: 100%;
    }
}

// Set the z-index so that the iframe is shown when the part-details are opened in a modal.
#article-details__frame {
    &__safety-data-sheet {
        z-index: 25;
    }
}